<template>
  <div>
    <div v-if="leadbooking?.provisional">
      <h3 class="mb-0">Provisional</h3>
    </div>
    <div v-if="leadbooking?.provisional" class="mb-4">
      Why: {{ leadbooking.provisionalnotes }}
    </div>

    <div>
      <h3 class="mb-0" @click="onAddressclick">Address</h3>
    </div>
    <div @click="onAddressclick">
      {{ actProperty.formatAddress(leadbooking?.address, ", ") }}
    </div>
    <div>
      <div class="mt-4">
        <h3 class="mb-0">Job</h3>
      </div>
      <div>
        {{ actProperty.formatReportType(leadbooking?.jobtype, dictionary) }}
        / {{ customLabelForInternaljobtype(leadbooking?.internaljobtype) }}
      </div>
    </div>
    <div>
      <div v-if="booking?.customer" class="mt-4">
        <h3 class="mb-0">Client</h3>
      </div>
      <div
        v-if="
          booking?.customer &&
          (booking.customer.companyName || booking.customer.branchName)
        "
      >
        {{ booking.customer.companyName }} -
        {{ booking.customer.branchName }}
      </div>
    </div>
    <div v-if="booking.subtype != 'Prep'">
      <div class="mt-4">
        <h3 class="mb-0">Inspector</h3>
      </div>
      <div v-if="booking?.inspector">
        {{ booking.inspector.name }}
        <div v-if="prepandkeybookings?.length > 0">
          <div
            v-for="(prepdata, $prepdata) in prepandkeybookings"
            :key="$prepdata"
          >
            <div v-if="prepdata?.inspector.name">
              {{ prepdata.inspector.name }} - ({{ prepdata.subtype }})
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-4" v-if="booking.jobtype !== 'property visit'">
      <h3 class="mb-0">Time</h3>
    </div>
    <div v-if="booking.jobtype !== 'property visit' && (booking?.startdate || booking?.enddate)">
      {{ booking.starttime }}
      to
      {{ booking.endtime }}
    </div>
    <div v-if="booking.subtype === 'Prep'">
      <div
        v-if="booking?.leadbooking?.startdate || booking.leadbooking?.enddate"
      >
        {{ booking?.leadbooking?.bookingdate }} -
        {{ booking?.leadbooking?.starttime }}
        to
        {{ booking?.leadbooking?.endtime }} - ({{ masterReportType }})
      </div>
      <div class="mt-4">
        <h3 class="mb-0">Inspector</h3>
      </div>
      <div v-if="booking.inspector">
        {{ booking.inspector.name }}
      </div>
      <div v-if="booking?.leadbooking">
        {{ booking?.leadbooking?.inspector.name }} - ({{ masterReportType }})
      </div>
    </div>
    <div v-if="prepandkeybookings?.length">
      <div v-for="(prepdata, $prepdata) in prepandkeybookings" :key="$prepdata">
        <div v-if="prepdata?.startdate || prepdata?.enddate">
          {{ prepdata.bookingdate }} -
          {{ prepdata.starttime }}
          to
          {{ prepdata.endtime }} - ({{ prepdata.subtype }})
        </div>
      </div>
    </div>
    <div>
      <div v-if="booking?.appointmenttime" class="mt-4">
        <h3 class="mb-0">Appointment time</h3>
      </div>
      <div v-if="booking?.appointmenttime">
        {{ appointmenttime }}
      </div>
      <div
        v-if="
          appointmenttime === 'No Tenant but Fixed Time' &&
          booking?.fixedtimenotes
        "
      >
        Fixed time notes: {{ booking.fixedtimenotes }}
      </div>
    </div>

    <div class="mt-4" v-if="leadbooking?.propertyspec">
      <h3 class="mb-0">Property</h3>
      
      <div v-if="leadbooking.propertyspec.propertytype || leadbooking.propertyspec.furnished || propertyspec">
        <div v-if="leadbooking.propertyspec.propertytype" class="mr-4">
          Property type: {{ leadbooking.propertyspec.propertytype }}
        </div>
        <div v-if="leadbooking.propertyspec.furnished" class="mr-4">
          Furnished: {{ leadbooking.propertyspec.furnished }}
        </div>
        <div v-if="propertyspec">
          {{ propertyspec }}
        </div>
      </div>
    </div>

    <div class="mt-4" v-if="leadbooking?.bookedbypresent">
      <div>
        <h3 class="mb-0">Booked by:</h3>
      </div>
      <div
        v-for="(bookedby, $bookedbyindex) in leadbooking.bookedby"
        :key="$bookedbyindex"
      >
        <span>{{ getBookedbydetails(bookedby) }}</span
        ><br />
      </div>
    </div>
    <div>
      <div v-if="leadbooking?.keypickup" class="mt-4">
        <h3 class="mb-0">Access</h3>
      </div>
      <div v-if="leadbooking?.keypickup">
        {{ access }}<span>{{ accesstbc }}</span>
      </div>
      <div v-if="leadbooking?.releasekeysto" class="mt-4">
        <h3 class="mb-0">Release</h3>
      </div>
      <div v-if="leadbooking?.keystobereleased">{{ keytobereleased }}</div>
      <div v-if="leadbooking?.releasekeysto">{{ release }}</div>
    </div>
    <div>
      <div v-if="leadbooking?.keypickup" class="mt-4">
        <h3 class="mb-0">Tenants</h3>
      </div>
      <div v-if="leadbooking?.tenantattending === 'yes'">
        Are tenants attending - Yes
      </div>
      <div v-else-if="leadbooking?.tenantattending === 'no'">
        Are tenants attending - No
      </div>
      <div v-else>Are tenants attending - TBC</div>
      <div v-if="leadbooking?.tenants.length > 0">
        <div
          v-for="(tenant, $tenantindex) in leadbooking.tenants"
          :key="$tenantindex"
        >
          <span>{{ getTenantdetails(tenant) }}</span
          ><br />
        </div>
      </div>
    </div>
    <div>
      <div v-if="leadbooking?.landlordpresent" class="mt-4">
        <h3 class="mb-0">Landlord</h3>
      </div>
      <div v-if="leadbooking?.landlordpresent">
        <div
          v-for="(landlord, $landlordindex) in leadbooking.landlords"
          :key="$landlordindex"
        >
          <span>{{ getLandlorddetails(landlord) }}</span
          ><br />
        </div>
      </div>
    </div>

    <div
      v-if="
        leadbooking?.internalnotes ||
        leadbooking?.clientnotes ||
        booking?.pinotes ||
        booking?.accessissues
      "
      class="mt-4"
    >
      <h3 class="mb-0">Notes</h3>
    </div>

    <div v-if="leadbooking.id != booking.id && leadbooking?.accessissues">
      Master access notes: {{ leadbooking.accessissues }}
    </div>
    <div v-if="booking?.accessissues">
      Access notes: {{ booking.accessissues }}
    </div>
    <div v-if="booking?.pinotes">PI notes: {{ booking.pinotes }}</div>
    <div v-if="leadbooking.id != booking.id && leadbooking?.pinotes">
      Master PI notes: {{ leadbooking.pinotes }}
    </div>

    <div v-if="leadbooking?.internalnotes">
      ACT office notes: {{ leadbooking.internalnotes }}
    </div>

    <div v-if="leadbooking?.clientnotes">
      Client notes: {{ leadbooking?.clientnotes }}
    </div>
    <div v-if="leadbooking?.titlenotes">
      Title notes: {{ leadbooking.titlenotes }}
    </div>
    <div v-if="leadbooking?.longtermnotes">
      Long term notes: {{ leadbooking.longtermnotes }}
    </div>

    <span v-if="!pischeduler">
      <div class="mt-4" v-if="cost || worksorder">
        <h3 class="mb-0">Cost</h3>
      </div>
      <div v-if="cost">Cost: {{ actProperty.formatCurrency(cost) }}</div>
      <div v-if="worksorder">Worksorder: {{ worksorder }}</div>
    </span>
    <span v-if="booking.basereport && booking.basereport.ref">
      <h3 class="mb-0 mt-4">Previous booking details</h3>
      <div>
        {{ actProperty.formatReportType(booking.basereport.type, dictionary) }}
        {{ actProperty.formatDateForDisplay(booking.basereport.date) }}
      </div>
      <div>Ref: {{ booking.basereport.ref }}</div>
      <div v-if="previousreportpi">{{ previousreportpi }}</div>
    </span>
  </div>
</template>

<script setup lang="ts">
import { computed, PropType, inject, defineProps } from "vue";
import _get from "lodash/get";
import moment from "moment-timezone";
import { useStore } from "vuex";
import { Bookedby, Booking, Landlord, Tenant, Report } from "@/models";

const actProperty: any = inject("actProperty");

const props = defineProps({
  booking: { type: Booking },
  leadbooking: { type: Booking },
  prepandkeybookings: Array as PropType<Booking[]>,
});
const store = useStore();
const cognitoUser = computed(() => store.getters["auth/cognitoUser"]);
const dictionary = computed(() => store.getters["dictionary/current"]);
const internaljobtypedisplayname = computed(
  (): Map<string, string> => store.getters["diary/internaljobtypedisplayname"]
);
const isDiaryUser = computed(() => {
  const assignedGroups = _get(
    cognitoUser.value,
    'signInUserSession.accessToken.payload["cognito:groups"]',
    []
  );
  return assignedGroups.some((group: string) => group === "DiaryUser");
});
const pischeduler = computed(() => screen.width <= 760 && isDiaryUser.value);

function onAddressclick() {
  if (pischeduler.value) {
    const address = actProperty.formatAddress(props.leadbooking?.address, ", ");
    window.open(
      `http://maps.google.com/?q=${encodeURIComponent(address)}`,
      "_blank",
      "noreferrer"
    );
  }
}

const propertyspec = computed(() => {
  let lines = [];
  const spec = props.leadbooking?.propertyspec;
  if (spec?.bedrooms) lines.push(`${spec.bedrooms} bedrooms`);
  if (spec?.bathrooms) lines.push(`${spec.bathrooms} bathrooms`);
  if (spec?.receptions) lines.push(`${spec.receptions} receptions`);
  if (spec?.other) lines.push(`${spec?.other} other`);
  return lines.join(", ");
});

const appointmenttime = computed(() => {
  let apptime = moment(props.booking?.appointmenttime).utc().format("h:mm a");
  if (apptime === "12:00 am") return "No Tenant but Fixed Time";
  if (apptime === "12:15 am") return "Flexi all day";
  if (apptime === "12:20 am") return "Flexi AM";
  if (apptime === "12:25 am") return "Flexi PM";
  if (apptime === "12:30 am") apptime = "Flexi 9 till 1.30";
  if (apptime === "12:35 am") apptime = "Flexi 1.30 till 6";
  return apptime;
});

const access = computed(() => {
  var response = "";
  switch (props.leadbooking?.keypickup) {
    case "From Agency":
      response =
        "Access via Agency - " +
        props.leadbooking?.customer?.companyName +
        " - " +
        props.leadbooking?.keypickupbranchname +
        " (" +
        actProperty.formatAddress(
          props.leadbooking?.keypickupfromaddress,
          ", "
        ) +
        ")";
      break;
    case "Meet Tenant":
      if (props.leadbooking?.tenantattending === "no") {
        response = release.value;
      } else {
        response = "Meeting tenant at the property";
      }
      response = `${response} - ${actProperty.formatAddress(
        props.leadbooking?.keypickupfromaddress,
        ", "
      )}`;
      break;
    case "Via Concierge":
      response = `Via Concierge - ${actProperty.formatAddress(
        props.leadbooking?.keypickupfromaddress,
        ", "
      )}`;
      break;
    case "Via Landlord":
      response = `Meeting landlord at the property - ${actProperty.formatAddress(
        props.leadbooking?.keypickupfromaddress,
        ", "
      )}`;
      break;
    case "TBC":
      response = props.leadbooking?.confirmaccess
        ? "Confirmed": "To be confirmed";
      response = `${response} - ${actProperty.formatAddress(
        props.leadbooking?.keypickupfromaddress,
        ", "
      )}`;
      break;
    case "Misc":
      response = `Misc - ${actProperty.formatAddress(
        props.leadbooking?.keypickupfromaddress,
        ", "
      )}`;
      break;
    default:
      response = "To be confirmed";
      break;
  }
  return response;
});

const previousreportpi = computed(() => {
  let pi = props.booking.previousreportpi;
  if (pi) pi = `PI: ${pi}`;
  return pi;
});

const release = computed(() => {
  var response = "";
  switch (props.booking?.releasekeysto) {
    case "To Agency":
      response =
        "Keys released to branch (" +
        actProperty.formatAddress(
          props.leadbooking?.keyreleasetoaddress,
          ", "
        ) +
        ")";
      break;
    case "Meet Tenant":
      response = "Meeting tenant at the property";
      response = `${response} - ${actProperty.formatAddress(
        props.leadbooking?.keyreleasetoaddress,
        ", "
      )}`;
      break;
    case "Via Concierge":
      response = `Via Concierge - ${actProperty.formatAddress(
        props.leadbooking?.keyreleasetoaddress,
        ", "
      )}`;
      break;
    case "Via Landlord":
      response = `Meeting landlord at the property - ${actProperty.formatAddress(
        props.leadbooking?.keyreleasetoaddress,
        ", "
      )}`;
      break;
    case "TBC":
      response = props.leadbooking?.confirmaccess
        ? "Confirmed"
        : "To be confirmed";
      response = `${response} - ${actProperty.formatAddress(
        props.leadbooking?.keyreleasetoaddress,
        ", "
      )}`;
      break;
    case "Misc":
      response = `Misc - ${actProperty.formatAddress(
        props.leadbooking?.keyreleasetoaddress,
        ", "
      )}`;
      break;
    default:
      response = "To be confirmed";
      break;
  }
  return response;
});

const keytobereleased = computed(() => {
  var response = "";
  switch (props.booking?.keystobereleased) {
    case "Yes":
      response = "Keys to be released ";
      break;
    case "No":
      response = "Keys are not released";
      break;
    case "TBC":
      response = "Key release To be confirmed";
      break;
  }
  return response;
});

const accesstbc = computed(() => {
  var response = "";
  if (
    props.leadbooking?.keypickup === "Meet Tenant" &&
    props.leadbooking.tenantattending != "yes" &&
    props.leadbooking.tenantattending != "no"
  ) {
    response = " - TBC";
  } else if (!props.leadbooking?.confirmaccess) {
    response = " - TBC";
  } else {
    response = " - confirmed";
  }
  return response;
});

const masterReportType = computed(() => {
  return actProperty.formatReportType(
    props.booking.leadbooking?.jobtype,
    dictionary
  );
});

function getTenantdetails(tenant: Tenant) {
  let lines: string[] = [];
  if (tenant.ttname) lines.push(`${tenant.ttname}`);
  if (tenant.ttemail) lines.push(`Email: ${tenant.ttemail}`);
  if (tenant.ttmobile) lines.push(`Mobile: ${tenant.ttmobile}`);
  return lines.join(", ");
}

function getBookedbydetails(bookedby: Bookedby) {
  let lines: string[] = [];
  if (bookedby.bbname) lines.push(`${bookedby.bbname}`);
  if (bookedby.bbemail) lines.push(`Email: ${bookedby.bbemail}`);
  if (bookedby.bbmobile) lines.push(`Mobile: ${bookedby.bbmobile}`);
  return lines.join(", ");
}

function getLandlorddetails(landlord: Landlord) {
  let lines: string[] = [];
  if (landlord.llname) lines.push(`${landlord.llname}`);
  if (landlord.llemail) lines.push(`Email: ${landlord.llemail}`);
  if (landlord.llmobile) lines.push(`Mobile: ${landlord.llmobile}`);
  return lines.join(", ");
}

const cost = computed(() => {
  return props.leadbooking?.invoices.reduce(
    (sum: number, invoice: Report) => sum + invoice.totalFees(),
    0
  );
});

const worksorder = computed(() => {
  return props.leadbooking?.worksorder;
});

const customLabelForInternaljobtype = (internaljobtype: string): string => {
  return actProperty.customLabelForInternaljobtype(
    internaljobtypedisplayname.value,
    internaljobtype
  );
};
</script>
