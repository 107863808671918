import _ from 'lodash';

import { GetterTree } from 'vuex';
import { RootState } from '../types';
import { AuthState } from './types';

/**
 * Getters
 *
 * Vuex allows us to define "getters" in the store.
 * - You can think of them as computed properties for stores.
 * - Like computed properties, a getter's result is cached based on its dependencies,
 *   and will only re-evaluate when some of its dependencies have changed.
 */
export const getters: GetterTree<AuthState, RootState> = {

  /**
   * @param {AuthState} state 
   * @returns void
   */
  fullName: (state: AuthState) => !_.isEmpty(state.user) ? state.user.firstName + '' + state.user.lastName : '',

  /**
   * Retrieve cognito user
   * 
   * @param {AuthState} state 
   * @returns void
   */
  cognitoUser: (state: AuthState) => state.cognitoUser,

  /**
   * Check if signed in
   * 
   * @param {AuthState} state 
   * @returns void
   */
  signedIn: (state: AuthState) => state.cognitoUser !== null,

  /** 
   * @param {AuthState} state 
   * @returns void
   */
  email: (state: AuthState) => _.get(state.cognitoUser, 'attributes.email', ''),

  /**
   * Retrieves the users device key
   * 
   * @param {AuthState} state 
   * @returns void
   */
  deviceKey: (state: AuthState) => _.get(state.cognitoUser, 'signInUserSession.accessToken.payload.device_key', ''),

  /**
   * 
   * @param {AuthState} state 
   * @returns void
   */
  groups: (state: AuthState) => _.get(state.cognitoUser, 'signInUserSession.accessToken.payload["cognito:groups"]', []),

  /**
   * Is user a SysAdmin?
   * 
   * @param {AuthState} state 
   * @returns void
   */
  isSysAdmin: (state: AuthState) => _.get(state.cognitoUser, 'signInUserSession.accessToken.payload["cognito:groups"]', []).includes('SysAdmin'),

  /**
   * Is user an Administrator?
   * 
   * @param {AuthState} state 
   * @returns void
   */
  isAdministrator: (state: AuthState) => _.get(state.cognitoUser, 'signInUserSession.accessToken.payload["cognito:groups"]', []).includes('Administrators'),

  /**
   * Is user an Inspector?
   * 
   * 
   * @param {AuthState} state 
   * @returns void
   */
  isInspector: (state: AuthState) => _.get(state.cognitoUser, 'signInUserSession.accessToken.payload["cognito:groups"]', []).includes('Inspectors'),

  /**
   * Is user a DiaryUser?
   * 
   * 
   * @param {AuthState} state 
   * @returns void
   */
  isDiaryUser: (state: AuthState) => _.get(state.cognitoUser, 'signInUserSession.accessToken.payload["cognito:groups"]', []).includes('DiaryUser'),
   /**
   * Is user a DiaryUser?
   * 
   * 
   * @param {AuthState} state 
   * @returns void
   */
   isPropertyVisitBookingsAdministratorUser: (state: AuthState) => _.get(state.cognitoUser, 'signInUserSession.accessToken.payload["cognito:groups"]', []).includes('PropertyVisitBookingsAdministrator'),

  /**
   * Is user a QualityControl user?
   * 
   * 
   * @param {AuthState} state 
   * @returns void
   */
  isQCUser: (state: AuthState) => _.get(state.cognitoUser, 'signInUserSession.accessToken.payload["cognito:groups"]', []).includes('QualityControl'),
    /**
   * Is user a BookingManagement user?
   * 
   * 
   * @param {AuthState} state 
   * @returns void
   */
    isBookingManagement: (state: AuthState) => _.get(state.cognitoUser, 'signInUserSession.accessToken.payload["cognito:groups"]', []).includes('BookingManagement'),


  /**
   * Is user a Customer?
   * 
   * @param {AuthState} state 
   * @returns void
   */
  isCustomer: (state: AuthState) => _.get(state.cognitoUser, 'signInUserSession.accessToken.payload["cognito:groups"]', []).includes('Customers'),

  /**
   * Is user an Dataentry?
   * 
   * @param {AuthState} state 
   * @returns void
   */
  isDataentry: (state: AuthState) => _.get(state.cognitoUser, 'signInUserSession.accessToken.payload["cognito:groups"]', []).includes('Dataentry'),

  /**
   * Is user a booking staff?
   * 
   * @param {AuthState} state 
   * @returns void
   */
  isBooking: (state: AuthState) => _.get(state.cognitoUser, 'signInUserSession.accessToken.payload["cognito:groups"]', []).includes('Booking'),

  /**
   * Is user a Customer?
   * 
   * @param {AuthState} state 
   * @returns void
   */
  companyName: (state: AuthState) => _.get(state.cognitoUser, 'attributes["custom:company_name"]', ''),
};
