/* tslint:disable:no-console */

import _cloneDeepWith from "lodash/cloneDeepWith";
import _get from "lodash/get";
import _keys from "lodash/keys";
import _map from "lodash/map";
import _isEmpty from "lodash/isEmpty";
import _isString from "lodash/isString";
import _merge from "lodash/merge";
import _sortBy from "lodash/sortBy";
import _set from "lodash/set";
import _zipObject from "lodash/zipObject";
import _castArray from "lodash/castArray";

import Vue from "vue";
import { isProxy, toRaw } from "vue";
import camelcaseKeys from "camelcase-keys";
import hash from "object-hash";
import moment from "moment-timezone";
import { parse as urlParse } from "url";
import { v4 as uuidv4 } from "uuid";

import { useToast } from "vue-toastification";

import defaultdictionary from "./store/dictionary/json/dictionary.json";
import defaultschedules from "@/store/inspectors/json/schedules.json";
import {
  Address,
  Photo,
  Report,
  Room,
  Section,
  Type,
  Item,
  Note,
  Inspector,
  Location,
  Dictionary,
  DictionaryReportType,
  Booking,
  Schedule,
  ReportTiming,
  Customer,
  Auditlog,
  BookingBlock,
  EmailLogs,
} from "./models";

const timezone = process.env.VUE_APP_TIMEZONE || "Europe/London";
export const bookingdateformat = "YYYY-MM-DD[T]HH:mm:ss.SSSZ";
export const bookingdateutcformat = "YYYY-MM-DD[T]HH:mm:ss.SSS";
export const schedulerdateutcformat = "YYYYMMDD[T000000][Z]";
export const schedulerenddateutcformat = "YYYYMMDD[T235959][Z]";
const auditentitylist: Map<string, string[]> = new Map([
  ["Booking", ["startdate", "enddate", "appointmenttime"]],
]);

export function hashObj(obj: any): string {
  return hash(obj);
}

export function copyInto(
  destination: any,
  source: any,
  useCamelCase: boolean = true
): void {
  if (useCamelCase) {
    return _merge(destination, toCamelCase(source));
  }
  return _merge(destination, source);
}

export function toCamelCase(source: any): any {
  return camelcaseKeys(source, {
    deep: true,
    exclude: ["_uuid", "set_1", "set_2"],
  });
}

export function detectCircular(
  obj: any,
  seen = new WeakSet()
): boolean | string {
  // Check if value is an object
  if (obj && typeof obj === "object") {
    // Check for circular reference
    if (seen.has(obj)) {
      // Try to identify the object by looking for common properties
      const identifiers = ["id", "name", "type", "__v_isRef", "__v_raw"]
        .filter((prop) => obj[prop])
        .map((prop) => `${prop}: ${obj[prop]}`);
      return `Circular reference found: ${identifiers.join(", ")}`;
    }

    seen.add(obj);

    // Recursively check all nested properties
    for (const key in obj) {
      const result = detectCircular(obj[key], seen);
      if (result) {
        return `In property "${key}": ${result}`;
      }
    }
  }
  return false;
}

export function cleanupCircularReferences(obj: any): any {
  if (obj === null || typeof obj !== "object") {
    return obj;
  }

  // Handle Vue reactivity
  if (isProxy(obj)) {
    obj = toRaw(obj);
  }

  // Create a new object/array without circular refs
  const clean = Array.isArray(obj) ? [] : {};

  // Track processed objects to prevent circular references
  const seen = new WeakSet();

  function _cleanup(o: any): any {
    if (o === null || typeof o !== "object") {
      return o;
    }

    // Break circular reference
    if (seen.has(o)) {
      return undefined;
    }

    seen.add(o);

    const result = Array.isArray(o) ? [] : {};

    Object.keys(o).forEach((key) => {
      // Skip known problematic properties
      if (key === "__v_raw" || key === "__v_isRef") {
        return;
      }
      result[key] = _cleanup(o[key]);
    });

    return result;
  }

  return _cleanup(obj);
}

/**
 * Sort an object by it's keys
 */
export function sortObjByKeys(obj: any) {
  const keys = _sortBy(_keys(obj), (key) => key);

  return _zipObject(
    keys,
    _map(keys, (key) => obj[key])
  );
}

/**
 * Omit properties by key, deeply.
 * Like lodash omit, but recursive.
 *
 * https://github.com/lodash/lodash/issues/723#issuecomment-194385740
 */
export function omitDeep(collection: any, excludeKeys: any) {
  function omitFn(value: any) {
    if (value && typeof value === "object") {
      excludeKeys.forEach((key: string) => {
        delete value[key];
      });
    }
  }

  return _cloneDeepWith(collection, omitFn);
}
/**
 * Format in GBP
 */
export function formatCurrency(amount: string) {
  const num = parseFloat(amount);
  if (Number.isNaN(num)) {
    throw Error("Number cannot be determined");
  }
  return new Intl.NumberFormat("en-GB", {
    style: "currency",
    currency: "GBP",
  }).format(num);
}

/**
 * Format date for display.
 *
 * @param date string|number|Date
 * @return string
 */
export function formatDateForDisplay(date?: string | number | Date) {
  return moment.utc(date).tz(timezone).format("DD/MM/YYYY");
}

/**
 * Format time for display.
 *
 * @param date string|number|Date
 * @return string
 */
export function formatTimeForDisplay(date?: string | number | Date) {
  return moment.utc(date).tz(timezone).format("hh:mm A");
}

/**
 * Format datetime for display.
 *
 * If datetime is not supplied then moment() uses "now".
 *
 * @param datetime string|number|Date
 * @return string
 */
export function formatDatetimeForDisplay(datetime?: string | number | Date) {
  return moment.utc(datetime).tz(timezone).format("DD/MM/YYYY HH:mm");
}

/**
 * Format datetime for display.
 *
 * If datetime is not supplied then moment() uses "now".
 *
 * @param datetime string|number|Date
 * @return string
 */
export function formatUtcDatetimeForDisplay(datetime?: string | number | Date) {
  return moment(datetime).format("DD/MM/YYYY HH:mm");
}

/**
 * Reformat datetime (e.g. from datetime-local form inputs) in UTC "YYYY-MM-DDTHH:mm"
 *
 * @param datetime string|number|Date
 * @return string
 */
export function datetimeToUTC(datetime?: string | number | Date) {
  return moment.tz(datetime, timezone).utc().format("YYYY-MM-DD[T]HH:mm");
}

/**
 * Reformat datetime (e.g. from datetime-local form inputs) in local "YYYY-MM-DDTHH:mm"
 *
 * @param datetime string|number|Date
 * @return string
 */
export function datetimeToLocalTime(datetime?: string | number | Date) {
  return moment.utc(datetime).tz(timezone).format("YYYY-MM-DD[T]HH:mm");
}

/**
 * Reformat date (e.g. from date form inputs) in UTC "YYYY-MM-DD"
 *
 * @param date string|number|Date
 * @return string
 */
export function dateToUTC(date?: string | number | Date) {
  return moment.tz(date, timezone).utc().format("YYYY-MM-DD");
}

/**
 * Reformat date (e.g. from date form inputs) in UTC "YYYY-MM-DD"
 *
 * @param date string|number|Date
 * @return string
 */
export function dateToLocalTime(date?: string | number | Date) {
  return moment.utc(date).tz(timezone).format("YYYY-MM-DD");
}

/**
 * Parse given string into a date object
 *
 * @param date string|number|Date
 * @return string
 */
export function parseDate(date?: string | number | Date) {
  return moment.utc(date).tz(timezone).toDate();
}

export function differenceInMinutes(
  date1?: string | number | Date,
  date2?: string | number | Date
) {
  var diff = 0;
  if (date1 && date2)
    diff = moment
      .duration(moment.utc(date1).diff(moment.utc(date2)))
      .asMinutes();
  return diff;
}

export function differenceInSeconds(
  date1?: string | number | Date,
  date2?: string | number | Date
) {
  var diff = 0;
  if (date1 && date2)
    diff = moment
      .duration(moment.utc(date1).diff(moment.utc(date2)))
      .asSeconds();
  return diff;
}

export function prependDate(time: string, date: string, currentdate: Date) {
  let value = "";
  if (time) {
    let dt = date ? date : currentdate;
    let justdate = moment(dt).format("YYYY-MM-DD");
    let justtime = moment(time, "hh:mm A").format("HH:mm");
    value = `${justdate}T${justtime}:00.000Z`;
  }
  return value;
}

export function postfixTime(date: string, datetime: string) {
  let value = "";
  if (date) {
    let justtime = moment(datetime).format("HH:mm");
    let justdate = moment(date, "YYYY-MM-DD").format("YYYY-MM-DD");
    value = `${justdate}T${justtime}:00.000Z`;
  }
  return value;
}

export function postfixUTCTime(date: string, datetime: string) {
  let value = "";
  if (date) {
    let justtime = moment(datetime).utc().format("HH:mm");
    let justdate = moment(date, "YYYY-MM-DD").format("YYYY-MM-DD");
    value = `${justdate}T${justtime}:00.000Z`;
  }
  return value;
}

export function roundDate(datetime: string): string {
  const mom = moment(datetime);
  let remainder = 15 - (mom.minute() % 15);
  if (remainder === 15) remainder = 0;
  return moment(mom).add(remainder, "minutes").format(bookingdateformat);
}

/**
 * Convert "Report Type" lowercase string ids to friendly names
 *
 * @param type string
 * @return string
 */
export function formatReportType(type: string, dictionary: Dictionary) {
  let friendlyName;
  if (
    !dictionary ||
    !dictionary.reporttypes ||
    !dictionary.reporttypes.length
  ) {
    dictionary = Dictionary.parse(defaultdictionary);
  }
  if (dictionary && dictionary.reporttypes) {
    const reporttype: DictionaryReportType | undefined =
      dictionary.reporttypes.find((t) => t.slug === type);
    if (reporttype) {
      friendlyName = reporttype.displayname;
    }
  }

  // If couldn't determine friendly name from dictionary
  // fall back to the old style
  if (!friendlyName) {
    switch (type) {
      case "":
        friendlyName = "";
        break;
      case "inventory":
        friendlyName = "Inventory Make & Check In";
        break;
      case "soc":
        friendlyName = "Schedule Of Condition";
        break;
      case "checkin":
        friendlyName = "Check-In";
        break;
      case "checkout":
        friendlyName = "Check-Out";
        break;
      case "property visit":
        friendlyName = "Property Visit";
        break;
      default:
        friendlyName = type;
        break;
    }
  }
  return friendlyName;
}

/**
 * Convert "Report Type" short names
 * this is used in diary
 *
 * @param type string
 * @return string
 */
export function shortReportType(
  type: string,
  subtype: string,
  dictionary: Dictionary
) {
  const longtype: string = formatReportType(type, dictionary);
  let shorttype: string = longtype;
  switch (type) {
    case "inventory":
      if (subtype === Booking.PREP || subtype === Booking.SHARED)
        shorttype = "Inv";
      else shorttype = "InvCI";
      break;
    case "soc":
      shorttype = "SOC";
      break;
    case "checkin":
      shorttype = "CI";
      break;
    case "checkout":
      shorttype = "CO";
      break;
    case "property visit":
      shorttype = "PV";
      break;
  }
  return shorttype;
}

/**
 * Convert report friendly names to Report Type
 *
 * @param type string
 * @return string
 */
export function findReportType(friendlyname: string, dictionary: Dictionary) {
  let reportType;
  if (
    !dictionary ||
    !dictionary.reporttypes ||
    !dictionary.reporttypes.length
  ) {
    dictionary = Dictionary.parse(defaultdictionary);
  }
  if (dictionary && dictionary.reporttypes) {
    const reporttype: DictionaryReportType | undefined =
      dictionary.reporttypes.find((t) => t.displayname === friendlyname);
    if (reporttype) {
      reportType = reporttype.slug;
    }
  }

  // If couldn't determine friendly name from dictionary
  // fall back to the old style
  if (!reportType) {
    switch (friendlyname) {
      case "":
        reportType = "";
        break;
      case "Inventory Make & Check In":
        reportType = "inventory";
        break;
      case "Schedule Of Condition":
        reportType = "soc";
        break;
      case "Check-In":
        reportType = "checkin";
        break;
      case "Check-Out":
        reportType = "checkout";
        break;
      case "Property Visit":
        reportType = "property visit";
        break;
      default:
        reportType = friendlyname;
        break;
    }
  }
  return reportType;
}

export function isFactoryreport(reporttype: string) {
  let corereports = ["inventory", "soc", "checkin", "checkout"];
  let corereport = corereports.find((t) => t === reporttype);
  return !corereport;
}

/**
 * Format Address as a string
 *
 * @param address Address
 * @param separator string
 * @return string
 */
export function formatAddress(address: Address, separator: string = "<br />") {
  const items = [
    address.name,
    address.line1,
    address.line2,
    address.town,
    address.county,
    address.postcode,
    // address.country, // Assuming all GB, for now
  ];
  return items.filter((el) => !_isEmpty((el || "").trim())).join(separator);
}

/**
 * Remove an item from an array, using the item index
 *
 * @param items any[]
 * @param i number
 */
export function removeItemByIndex(items: any[], i: number) {
  return items.slice(0, i).concat(items.slice(i + 1, items.length));
}

/**
 * Swap 2 indexes in an array and return the resulting array
 *
 * @param array any[]
 * @param a number
 * @param b number
 * @return any[]
 */
export function swapArrayItems(array: any[], a: number, b: number): any[] {
  // console.log('swapArrayItems', 'array', array);
  // console.log('swapArrayItems', 'a', a);
  // console.log('swapArrayItems', 'b', b);
  let input = array;
  let temp = input[a];
  input[a] = input[b];
  input[b] = temp;
  return input;
}

/**
 * Swap 2 indexes in an array and return the resulting array
 *
 * @param array any[]
 * @param a number
 * @param b number
 * @return any[]
 */
export function confirmPrompt(message?: string): Promise<boolean> {
  if (!message) {
    message = "Are you sure?";
  }
  return new Promise((resolve, reject) => {
    if (confirm(message)) {
      resolve(true);
    } else {
      reject(false);
    }
  });
}

/**
 * Display Axios/AWS Error
 *
 * @param error Axios Error response
 */
export function displayError(error: any): void {
  console.error(error);
  let message;
  switch (true) {
    case _isString(error):
      message = error;
      break;
    case _get(error, "response.data.message", "").length > 0:
      message = _get(error, "response.data.message");
      break;
    case _get(error, "message", "").length > 0:
      message = _get(error, "message");
      break;
    default:
      message = "Changes not saved";
      break;
  }
  useToast().error(message, { timeout: 3000 });
}

/**
 * Full S3 URL of a given key, or the S3 origin if no key is supplied
 *
 * @param {String|undefined} key
 * @returns {String} Full URL to S3 Origin, with optional asset key
 */
export function s3Origin(key?: string): string {
  const origin = `https://${process.env.VUE_APP_S3_BUCKET}.s3.${process.env.VUE_APP_AWS_REGION}.amazonaws.com`;
  if (key === undefined) {
    return origin;
  }
  if (key.startsWith(origin)) {
    return key;
  }
  return `${origin}/${key}`;
}

/**
 * Request a photo using our AWS Serverless Image Handler.
 *
 * @see https://aws.amazon.com/solutions/serverless-image-handler/
 *
 * @param {Photo}   photo Photo, including `src` and optional `rotate` attribute
 * @param {object=} edits Edits, see Serverless Image Handler docs for more details
 *
 * @returns {string} Absolute URL to a Serverless Image Handler image
 */
export function photoSrc(photo: Photo, edits: object) {
  const { src, rotate } = photo;

  if (!src) {
    throw Error(`Photo has no src: ${JSON.stringify(photo)}`);
  }

  // Remove S3 Base Url prefix, if it exists
  if (photo.src.startsWith(s3Origin())) {
    photo.src = urlParse(photo.src).pathname!.slice(1);
  }

  // The Serverless Image Handler requires the Bucket and Key (without the S3 Bucket URL)
  const request = {
    bucket: process.env.VUE_APP_S3_BUCKET,
    key: photo.src,
    edits: {},
  };

  // Apply optional "edits" to the requested image (resize, etc)
  if (edits) {
    request.edits = edits;
  }

  // If the Photo has a rotate value, then set edits.rotate
  if (rotate) {
    _set(request, "edits.rotate", rotate);
  }

  // Encode the stringified request params
  const base64EncodedRequest = Buffer.from(JSON.stringify(request)).toString(
    "base64"
  );

  // Prepend the Serverless Image Handler base URL
  return `${process.env.VUE_APP_RESIZE_ORIGIN}/${base64EncodedRequest}`;
}

/**
 * Photo Gallery URL.
 *
 * @param {Photo}  photo  Photo
 * @param {Report} report Report
 * @param {string} type   Type: "in"| or "out"
 *
 * @returns {string} Absolute URL to this Photo in the Photo Gallery.
 */
export function photoGalleryUrl(photo: Photo, report: Report, type?: string) {
  const { src } = photo;
  const { id } = report;

  if (!src) {
    throw Error(`Photo has no src: ${JSON.stringify(photo)}`);
  }

  if (!id || id === "new") {
    return "";
  }

  // Encode the stringified request params
  const base64EncodedSrc = Buffer.from(src).toString("base64");

  if (type) {
    return `/photos/${id}/${base64EncodedSrc}/${type}`;
  }

  return `/photos/${id}/${base64EncodedSrc}`;
}

/**
 * Request a resized photo from our AWS Serverless Image Handler.
 *
 * This will resize the requested photo according to the pixel width set
 * in the PDF_IMG_WIDTH environment variable.
 *
 * @see https://aws.amazon.com/solutions/serverless-image-handler/
 *
 * @param {Photo} photo Photo, including src and optional rotate attribute
 *
 * @returns {string} Absolute URL to a Serverless Image Handler image
 */
export function resizedPhotoSrc(photo: Photo) {
  const edits = {
    resize: {
      width: parseInt(process.env.VUE_APP_RESIZE_IMG_WIDTH || "100"),
      fit: "contain",
    },
  };

  return photoSrc(photo, edits);
}

export function imageOrientation(photo: Photo, prefix: string) {
  let orientation = "even";
  let photoEl: any = document.getElementById(`${prefix}${photo.src}`);

  if (photoEl) {
    if (photoEl.width > photoEl.height) {
      orientation = "landscape";
    } else if (photoEl.width < photoEl.height) {
      orientation = "portrait";
    }
  }

  return orientation;
}

/**
 * Copy a "Download Report"/"Download Invoice" button, ready to be pasted into
 * a HTML email.
 *
 * Exists with an  (if the requested Attachment type exists).
 *
 * @param {Report} report         Report
 * @param {String} attachmentType Attachment type (invoice|report|summary)
 *
 * @return {Promise}
 */
export async function copyDownloadButton(
  report: Report,
  attachmentType: string
) {
  // This method only works for PDF Attachments (invoice|report|summary)
  if (["invoice", "report", "summary"].includes(attachmentType) === false) {
    throw Error(`Unexpected Attachment.type: ${attachmentType}`);
  }

  const url: string = _get(report, `attachments.${attachmentType}`, "");

  if (url) {
    try {
      const encodedFullUrl = encodeURI(prependDownloadsURL(url));

      console.info("📋 URL:", encodedFullUrl);

      const buttonText =
        attachmentType == "invoice" ? "Download Invoice" : "Download Report";

      // In Merlin this was 2 nested tables, but that was causing alignment issues for our new method
      const buttonHTML = `<table cellspacing="0" cellpadding="0" border="0" style="border: none;padding: 0;width: 200px;"><tr><td><a href="${encodedFullUrl}" target="_blank" style="display: block;text-align:center;background-color: #253746;border-radius: 2px;font-family: Helvetica, Arial, sans-serif;font-size: 14px;color: #fae021;text-decoration: none;font-weight:bold;width: 200px;height: 40px;line-height: 20px;padding: 10px;">${buttonText}</a></td></tr></table>`;

      // Create temp DOM Element
      const tmpElement = document.body.appendChild(
        document.createElement("span")
      );
      tmpElement.insertAdjacentHTML("beforeend", buttonHTML);
      tmpElement.id = "tmp-download-button";

      // console.info('📋 DOM Element:', tmpElement);

      // Select the DOM Element
      const range = document.createRange();
      range.selectNode(document.getElementById("tmp-download-button")!);
      window.getSelection()!.removeAllRanges();
      window.getSelection()!.addRange(range);

      // Copy & cleanup
      document.execCommand("copy");
      window.getSelection()!.removeAllRanges();
      tmpElement.remove();

      useToast().success(
        `"${buttonText}" button copied. Double-click to download.`,
        { timeout: 3000 }
      );
    } catch (err: any) {
      console.error(err);

      useToast().error("Unable to copy to clipboard.", { timeout: 3000 });
    }
  } else {
    useToast().error("Double-click to generate the PDF first.", {
      timeout: 3000,
    });
  }
}

/**
 * Copy a "Activate ACT" button, ready to be pasted into
 * a HTML email.
 *
 *
 * @param {String} customerid         String
 *
 * @return {Promise}
 */
export async function copyDepositoryActivationButton(customerid: string) {
  const authtoken: string = `${process.env.VUE_APP_DEPOSITORY_AUTH_TOKEN}`;
  const apiEndpointUrl: string = `${process.env.VUE_APP_DEPOSITORY_API_ENDPOINT}`;
  const returnurl: string = encodeURI(
    `${window.location.origin}/depository/linkbackconfirm`
  );
  const url: string = `${apiEndpointUrl}?clientId=${customerid}&authToken=${authtoken}&returnUrl=${returnurl}`;
  const buttonText: string = "Activate ACT";

  try {
    const encodedUrl = encodeURI(prependDownloadsURL(url));

    // In Merlin this was 2 nested tables, but that was causing alignment issues for our new method
    const buttonHTML = `<table cellspacing="0" cellpadding="0" border="0" style="border: none;padding: 0;width: 200px;"><tr><td><a href="${encodedUrl}" target="_blank" style="display: block;text-align:center;background-color: #253746;border-radius: 2px;font-family: Helvetica, Arial, sans-serif;font-size: 14px;color: #fae021;text-decoration: none;font-weight:bold;width: 200px;height: 40px;line-height: 20px;padding: 10px;">${buttonText}</a></td></tr></table>`;

    // Create temp DOM Element
    const tmpElement = document.body.appendChild(
      document.createElement("span")
    );
    tmpElement.insertAdjacentHTML("beforeend", buttonHTML);
    tmpElement.id = "tmp-download-button";

    // Select the DOM Element
    const range = document.createRange();
    range.selectNode(document.getElementById("tmp-download-button")!);
    window.getSelection()!.removeAllRanges();
    window.getSelection()!.addRange(range);

    // Copy & cleanup
    document.execCommand("copy");
    window.getSelection()!.removeAllRanges();
    tmpElement.remove();

    useToast().success(
      `"${buttonText}" button copied. Paste it inside an email to target client.`,
      { timeout: 3000 }
    );
  } catch (err: any) {
    console.error(err);
    useToast().error("Unable to copy to clipboard.", { timeout: 3000 });
  }
}

/**
 * Download a Report Attachment from the Downloads S3 CloudFront Distribution.
 *
 * Downloads in a new window.
 *
 * @param {string} key Attachment S3 Key (or full URL if Report is legacy)
 *
 * @return {Window|null}
 */
export function downloadAttachment(key: string): Window | null {
  const url = prependDownloadsURL(key);

  // We now only store the S3 Key, so construct the full S3 URL
  return window.open(url, "_blank");
}

/**
 * Prepend the full download URL to a file request (for an Attachemnt).
 *
 * @param {string} attachment Attachment location
 *
 * @return {string}
 */
export function prependDownloadsURL(attachment: string): string {
  // We originally stored the full S3 URL inc. protocol & domain into db
  if (attachment.startsWith("http://") || attachment.startsWith("https://")) {
    return attachment;
  }

  // We now only store the Attachment S3 Key, so construct the full S3 URL
  return `${process.env.VUE_APP_DOWNLOADS_URL}${attachment}`;
}

/**
 * Find booking that is previous to the given booking from the bookinglist
 *
 * @param {Booking} booking
 * @param {Booking[]} bookinglist
 *
 * @return {Booking}
 */
export function findPreviousBooking(
  booking: Booking,
  bookinglist: Booking[]
): Booking | undefined {
  var previousjob = undefined;
  const bookingstartdatemoment: any = moment.utc(booking.startdate);
  if (
    booking.inspector.id &&
    booking.address &&
    booking.address.postcode &&
    bookinglist &&
    bookinglist.length
  ) {
    previousjob = bookinglist
      .filter((b: Booking) =>
        bookingstartdatemoment.isSame(moment.utc(b.startdate), "day")
      )
      .filter(
        (b: Booking) =>
          b.id != booking.id &&
          b.inspector.id === booking.inspector.id &&
          moment.utc(b.startdate).isBefore(bookingstartdatemoment)
      )
      .sort((b1: Booking, b2: Booking) => {
        if (moment.utc(b1.startdate).isAfter(moment.utc(b2.startdate))) {
          return 1;
        } else if (
          moment.utc(b1.startdate).isBefore(moment.utc(b2.startdate))
        ) {
          return -1;
        } else {
          return 0;
        }
      })
      .find(
        (b: Booking, index: number, bookings: Booking[]) =>
          index === bookings.length - 1
      );
  }
  return previousjob;
}

/**
 * Find booking that is next to the given booking from the bookinglist
 *
 * @param {Booking} booking
 * @param {Booking[]} bookinglist
 *
 * @return {Booking}
 */
export function findNextBooking(
  booking: Booking,
  bookinglist: Booking[]
): Booking | undefined {
  var nextjob = undefined;
  const bookingstartdatemoment: any = moment.utc(booking.startdate);
  if (
    booking.inspector.id &&
    booking.address &&
    booking.address.postcode &&
    bookinglist &&
    bookinglist.length
  ) {
    nextjob = bookinglist
      .filter((b: Booking) =>
        bookingstartdatemoment.isSame(moment.utc(b.startdate), "day")
      )
      .filter(
        (b: Booking) =>
          b.id != booking.id &&
          b.inspector.id === booking.inspector.id &&
          moment.utc(b.startdate).isAfter(moment.utc(booking.startdate))
      )
      .sort((b1: Booking, b2: Booking) => {
        if (moment.utc(b1.startdate).isAfter(moment.utc(b2.startdate))) {
          return 1;
        } else if (
          moment.utc(b1.startdate).isBefore(moment.utc(b2.startdate))
        ) {
          return -1;
        } else {
          return 0;
        }
      })
      .find((b: Booking, index: number, bookings: Booking[]) => index === 0);
  }
  return nextjob;
}

export function sortInspectorList(
  list: Inspector[],
  params: { previousreport: Report; previousbooking: Booking; postcode: string }
) {
  params.postcode = params.postcode.replace(/ /g, "");
  let areacode = params.postcode.substring(0, params.postcode.length - 3);
  let unsortedlist = [...list];

  // Rank 1 - who did previous job, identified by PI name, user login or signature page of report.
  //    Should also show postcodes of other jobs that day.
  // Rank 2 is who is next closest, showing postcodes of other jobs.
  // Rank 3 is next closest.
  // Rank 4 is preferred areas. Preferred areas are of most value when booking in advance
  let sortedlist: Inspector[] = unsortedlist.sort(
    (i1: Inspector, i2: Inspector) => {
      // Rank 1 - based on previous job
      if (i1.id === params.previousbooking?.inspector?.id) {
        return -1;
      } else if (i2.id === params.previousbooking?.inspector?.id) {
        return 1;
      } else if (i1.email === params.previousreport?.firstexportedby) {
        return -1;
      } else if (i2.email === params.previousreport?.firstexportedby) {
        return 1;
      } else {
        // Rank 2 - based on area count
        let count1 = i1.getAreacount(params.postcode);
        let count2 = i2.getAreacount(params.postcode);

        if (count1 !== count2) {
          return count2 - count1; // Sort in descending order of count
        } else {
          // Rank 4 - preferred area
          let rating1 = 0;
          let index1 = i1.locations.findIndex(
            (loc: Location) => loc.code.trim() === areacode.trim()
          );
          if (index1 >= 0) rating1 = i1.locations[index1].rating;

          let rating2 = 0;
          let index2 = i2.locations.findIndex(
            (loc: Location) => loc.code.trim() === areacode.trim()
          );
          if (index2 >= 0) rating2 = i2.locations[index2].rating;

          if (rating1 !== rating2) {
            return rating2 - rating1; // Sort in descending order of rating
          } else {
            // Rank 3 - alphabetical order of name
            return i1.name.localeCompare(i2.name);
          }
        }
      }
    }
  );
  return sortedlist;
}

export function sortCustomerList(list: Customer[]) {
  let sortedlist = list.sort((c1: Customer, c2: Customer) => {
    let order = c1.companyName.localeCompare(c2.companyName);
    if (order === 0) {
      order = c1.branchName.localeCompare(c2.branchName);
    }
    return order;
  });
  return sortedlist;
}

export const regionlist: string[] = [
  "Region 1",
  "Region 2",
  "Region 3",
  "Region 4",
  "Region 5",
  "Region 6",
  "Region 7",
  "Region 8",
  "Region 9",
];
export const regioncolours: string[] = [
  "#F44336",
  "#FF4081",
  "#9C27B0",
  "#673AB7",
  "#3F51B5",
  "#2196F3",
  "#03A9F4",
  "#00BCD4",
  "#009688",
  "#4CAF50",
  "#8BC34A",
  "#CDDC39",
  "#FFC107",
  "#FF9800",
  "#FF5722",
  "#795548",
  "#9E9E9E",
  "#607D8B",
];

export function getAppointmentcolour(
  booking: Booking,
  inspectorlist: Inspector[]
) {
  let colour = "#cfcfcf";
  if (booking.inspector && booking.inspector.region) {
    colour = getRegionColor(booking.inspector.region);
  } else if (booking.employeeId && inspectorlist.length) {
    let insp: Inspector | undefined = inspectorlist.find(
      (i: Inspector) => i.id === booking.employeeId
    );
    if (insp) {
      colour = getRegionColor(insp.region);
    }
  }

  // Mute color fo PV
  if (
    booking.jobtype === "property visit" &&
    !booking.pvconfirmed
  ) {
    colour = `${colour}55`;
  }
  return colour;
}

export function getContrastYIQ(hexcolor: string): string {
  hexcolor = hexcolor.replace("#", "");
  const r = parseInt(hexcolor.substr(0, 2), 16);
  const g = parseInt(hexcolor.substr(2, 2), 16);
  const b = parseInt(hexcolor.substr(4, 2), 16);
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq >= 128 ? "black" : "white";
}

export function getRegionColor(val: string) {
  let colour = "#607D8B";
  let index = regionlist.findIndex((r: string) => r === val);
  if (index >= 0) {
    colour = regioncolours[index];
  }
  return colour;
}

const liabilityflags: string[] = ["CLN", "TT", "STTA", "CLR", "INF"];
export function checkitem(
  room: Room,
  section: Section,
  type: Type,
  item: Item
) {
  let result = item?.condition?.out?.notes.filter((note: Note) => {
    if (note?.responsibility?.length === 0) return false;
    let list = note?.responsibility?.filter((l: string) => {
      let i: number = liabilityflags.indexOf(l);
      return i >= 0;
    });
    return list ? list?.length > 0 : false;
  });
  return result?.length > 0;
}
export function determineRecommendedtime(
  booking: Booking,
  searchreoprttype: string
) {
  let timing: ReportTiming | undefined = undefined;
  if (booking.inspector) {
    let inspectorschedules: Schedule[] = [];
    if (
      !booking.inspector.schedules ||
      booking.inspector.schedules.length == 0
    ) {
      inspectorschedules = _castArray(
        _get(defaultschedules, "schedules", [])
      ).map((r: any) => new Schedule(r));
    } else {
      inspectorschedules = booking.inspector.schedules;
    }
    let filtereddefaultschedules = defaultschedules.schedules.filter(
      (schedule: any) => {
        let i = schedule.internaljobtypelist?.findIndex(
          (t: any) => t === booking.internaljobtype
        );
        return i >= 0 ? true : false;
      }
    );
    if (filtereddefaultschedules.length) {
      if (searchreoprttype) {
        const schedule: Schedule | undefined = inspectorschedules.find(
          (s) => s.reporttype === searchreoprttype
        );
        if (schedule && schedule.timinggroups) {
          const propertytypegroup = booking.propertytypegroup;
          if (propertytypegroup) {
            schedule.timinggroups.forEach((tg) => {
              if (tg.propertytypegroup && !timing) {
                timing = tg.propertytypegroup.find((ptg) => {
                  // This is to correct an unfortunate spelling mistake made in the default schedulers
                  const type = ptg.propertytype.replaceAll(
                    "Frunished",
                    "Furnished"
                  );
                  return type === propertytypegroup;
                });
              }
            });
          }
        }
      }
    }
  }

  return timing;
}

export function getBookingTitle(booking: Booking, dictionary: Dictionary) {
  let val = "";
  if (booking.subtype === Booking.KEY) {
    val = "Key collection";
  } else if (booking.isAllDay) {
    val = booking.summary;
  } else if (booking instanceof BookingBlock) {
    val = "PV Bookings";
  } else {
    let short = "";
    let jobtype = booking.jobtype;
    if (booking.leadbooking?.id) jobtype = booking.leadbooking.jobtype;
    short = shortReportType(jobtype, booking.subtype, dictionary);

    let subtype = booking.subtype;
    let multi = getMulti(booking);
    let master =
      booking.subbookings.length > 0 && !booking.subtype ? "Master" : "";
    let sublist = getSubtypelist(booking);
    sublist = sublist.replaceAll("Key", "Key collection");
    let commaindex = sublist.indexOf(",");
    let withword =
      commaindex > 0 || jobtype === "checkin" || jobtype === "inventory"
        ? "with"
        : "";
    let withsublist = sublist ? `${withword} ${sublist}` : "";
    let flexi = getFlexi(booking);
    let pvappointmenttime = getPvAppointmenttime(booking);
    let b2b = getB2B(booking);
    let list = [
      master,
      short,
      b2b,
      subtype,
      withsublist,
      multi,
      flexi,
      pvappointmenttime,
    ];
    val = list.filter((l) => l && l.trim().length > 0).join(" ");
  }
  return val;
}

export function getMulti(booking: Booking) {
  let val = "";
  if (!booking.subtype || booking.subtype === Booking.PREP) {
    // If this is a master booking with shared, count all
    let count = 0;
    booking.subbookings.forEach((sb: Booking) => {
      if (sb.subtype === Booking.SHARED) {
        count++;
      }
    });
    if (count > 0) {
      val = `1 of ${count + 1}`;
    }
  } else {
    let count = 0;
    let ordinal = 0;
    if (booking.leadbooking && booking.leadbooking.subbookings) {
      for (let i = 0; i < booking.leadbooking?.subbookings.length; i++) {
        let sb: Booking = booking.leadbooking.subbookings[i];
        if (sb.subtype === booking.subtype) {
          count++;
        }
        if (sb.id === booking.id) {
          ordinal = count + 1;
        }
      }
    }

    if (
      booking.subtype === Booking.SHARED &&
      (!booking.leadbooking?.subtype ||
        booking.leadbooking?.subtype === Booking.PREP)
    ) {
      count++;
    }

    if (count > 1) val = `${ordinal} of ${count}`;
  }
  return val;
}

export function getB2B(booking: Booking): string {
  let val = "";
  if (
    booking?.internaljobtype === "Check-In - back to back" ||
    booking?.connectedbooking?.internaljobtype === "Check-In - back to back"
  )
    val = "B2B";
  return val;
}

export function getSubtypelist(booking: Booking) {
  let list: string[] = [];
  if (booking.subbookings) {
    booking?.subbookings.forEach((b: Booking) => {
      if (b.subtype) {
        const index = list.indexOf(b.subtype);
        if (index < 0) list.push(b.subtype);
      }
    });
  }
  const index = list.indexOf(Booking.KEY);
  if (index >= 0) {
    list.splice(index, 1);
    list.push(Booking.KEY);
  }
  return list.join(", ");
}

export function getFlexi(booking: Booking) {
  let apptime = moment(booking.appointmenttime).utc().format("h:mm a");
  if (apptime === "12:00 am") apptime = "No Tenant but Fixed Time";
  else if (apptime === "12:15 am") apptime = "Flexi all day";
  else if (apptime === "12:20 am") apptime = "Flexi AM";
  else if (apptime === "12:25 am") apptime = "Flexi PM";
  else if (apptime === "12:30 am") apptime = "Flexi 9 till 1.30";
  else if (apptime === "12:35 am") apptime = "Flexi 1.30 till 6";
  else if (booking.jobtype === "property visit" && !booking.pvconfirmed) apptime = "TBC";
  else apptime = "";
  return apptime;
}

export function getPvAppointmenttime(booking: Booking) {
  let apptime = "";
  if (booking.jobtype === "property visit" && booking.appointmenttime)
    apptime = moment(booking.appointmenttime).utc().format("h:mm a");
  if (apptime.startsWith("12:") && apptime.endsWith("am")) apptime = "";
  return apptime;
}

export function isFiveam(date: string) {
  let fiveam = moment(date)
    .utc()
    .startOf("day")
    .add(5, "hours")
    .format(bookingdateformat);
  fiveam = fiveam.replace("+00:00", "Z");
  date = date.replace("+00:00", "Z");
  return date === fiveam;
}

export function getFilveam(date: string) {
  let fiveam = moment(date)
    .utc()
    .startOf("day")
    .add(5, "hours")
    .format(bookingdateformat);
  fiveam = fiveam.replace("+00:00", "Z");
  return fiveam;
}

export function getDeviceId() {
  let deviceId = localStorage.getItem("device_id");

  if (!deviceId) {
    // Generate a random device ID
    deviceId = uuidv4();

    // Store the device ID in local storage
    localStorage.setItem("device_id", deviceId);
  }

  return deviceId;
}

export function secondsToHoursMinutes(seconds: number): string {
  if (seconds < 0) {
    throw new Error("Seconds must be a non-negative number");
  }

  const hours = Math.floor(seconds / 3600);
  const minutes = Math.round((seconds % 3600) / 60);
  let timeString = "";

  // Handle hours
  if (hours > 0) {
    timeString += hours === 1 ? `${hours} hour` : `${hours} hours`;
  }

  // Handle minutes
  if (minutes > 0) {
    if (timeString) timeString += " ";
    timeString += minutes === 1 ? `${minutes} min` : `${minutes} mins`;
  }

  // Handle case where seconds is 0
  if (!timeString) {
    timeString = "0 mins";
  }

  return timeString;
}

export function toRawObject(data: any) {
  let rawData = data;

  if (isProxy(rawData)) {
    rawData = toRaw(data);
  }
  return rawData;
}

export function convertToHoursMinutes(val: number) {
  let hourMinutes = moment
    .utc()
    .startOf("day")
    .add(val, "minutes")
    .format("HH [Hours] mm [Minutes]");
  hourMinutes = hourMinutes.replaceAll("00 Hours", "");
  hourMinutes = hourMinutes.replaceAll("00 Minutes", "");
  return hourMinutes;
}

export function customLabelForInternaljobtype(
  internaljobtypedisplayname: any,
  internaljobtype: string
) {
  var displayname = internaljobtype;
  if (internaljobtypedisplayname.get(internaljobtype)) {
    displayname = internaljobtypedisplayname.get(internaljobtype);
  }
  return displayname;
}

export function findLastIssueData(auditLogs: Auditlog[], fieldName: string) {
  for (let i = auditLogs.length - 1; i >= 0; i--) {
    const auditLog = auditLogs[i];
    const valueChange = auditLog.valuechanges.find(
      (vc) => vc.fieldname === fieldName && vc.newvalue === "Yes"
    );
    if (valueChange) {
      return auditLog;
    }
  }
  return null; // return null if no match is found
}

export function checkOtherTenantsResponse(
  booking: Booking,
  emailaddress: string,
  val: string
) {
  let response = true;
  if (booking?.tenants) {
    const index = booking.tenants.findIndex(
      (t) => t.ttemail != emailaddress && (!t.attending || t.attending != val)
    );
    response = index < 0;
  }
  return response;
}
export function pvDetermineViableSlot(booking: Booking, ins: Inspector) {
  let scheduledateAsDate = moment(booking.startDate).utc().toDate();
  let currentdatevalue = moment(booking.startDate).utc().format("YYYY-MM-DD");
  let pibookings = ins.bookings;

  let filteredBookings = pibookings.filter((b) => {
    if (!b.jobtype) return false;
    if (b.allDay) return false;
    let bookingstartdate = moment(b.startdate).utc().format("YYYY-MM-DD");

    if (b.recurrenceRule) {
      // Check if this recurring event is falling on to today
      return b.checkRecurrance(scheduledateAsDate);
    } else {
      if (b.jobtype === "property visit") {
        const booked_index = Booking.BOOKING_STATUS_LIST.indexOf(
          Booking.BOOKING_STATUS_BOOKED
        );
        const status_index = Booking.BOOKING_STATUS_LIST.indexOf(b.status);
        return status_index >= booked_index;
      }
      return bookingstartdate === currentdatevalue;
    }
  });
  filteredBookings.sort((a, b) =>
    moment.utc(a.starttime, "h:mm a").diff(moment.utc(b.starttime, "h:mm a"))
  );
  let start = "09:00";
  let end = "18:00";
  if (booking?.slottype === "Morning") {
    end = "13:30";
  } else if (booking?.slottype === "Afternoon") {
    start = "13:30";
  }

  // According to new undesrstanding, a viable gap needs to be found for
  // every positive response from tenant
  // first we attempt to place the booking next to a non-solid booking
  // if that is not possible, we attempt to place it next to a solid booking
  let foundGap = findViableGap(start, end, filteredBookings, 30);
  // If we could not find a gap, we try to find a gap in the non-solid bookings
  if (!foundGap) {
    const onlySolidBookings = filteredBookings.filter((b) => {
      if (b.jobtype === "property visit") {
        const booked_index = Booking.BOOKING_STATUS_LIST.indexOf(
          Booking.BOOKING_STATUS_BOOKED
        );
        const status_index = Booking.BOOKING_STATUS_LIST.indexOf(b.status);
        return status_index >= booked_index;
      } else {
        return true;
      }
    });
    foundGap = findViableGap(start, end, onlySolidBookings, 30);
  }

  // If we could not find a gap, we try to find a gap in the solid bookings
  if (!foundGap) {
    const onlyNonPVBookings = filteredBookings.filter(
      (b) => b.jobtype != "property visit"
    );
    foundGap = findViableGap(start, end, onlyNonPVBookings, 30);
  }
  return foundGap;
}
export function determineViableSlot(
  booking: Booking,
  ins: Inspector,
  start: string = "09:00",
  end: string = "18:00"
) {
  let scheduledateAsDate = moment(booking.scheduleddate).utc().toDate();
  let currentdatevalue = moment(booking.scheduleddate)
    .utc()
    .format("YYYY-MM-DD");
  const cachedate = moment(booking.scheduleddate).utc().format("DD-MM-YYYY");
  let pibookings = ins.bookingsmap.get(cachedate);
  if (!pibookings) {
    pibookings = ins.bookings;
  }

  const filteredBookings = pibookings.filter((b) => {
    if (!b.jobtype) return false;
    if (b.allDay) return false;
    if (b.tenancyid === booking.tenancyid) return false;
    let bookingstartdate = moment(b.startdate).utc().format("YYYY-MM-DD");

    if (b.recurrenceRule) {
      // Check if this recurring event is falling on to today
      return b.checkRecurrance(scheduledateAsDate);
    } else {
      return bookingstartdate === currentdatevalue;
    }
  });
  filteredBookings.sort((a, b) =>
    moment.utc(a.starttime, "h:mm a").diff(moment.utc(b.starttime, "h:mm a"))
  );

  if (booking?.slottype === "Morning") {
    end = "13:30";
  } else if (booking?.slottype === "Afternoon") {
    start = "13:30";
  }
  return findViableGap(start, end, filteredBookings, 30);
}

function timeToMinutes(time: string): number {
  const [hours, minutes] = time.split(":").map(Number);
  return hours * 60 + minutes;
}

function minutesToTime(minutes: number): string {
  const hours = Math.floor(minutes / 60);
  const mins = minutes % 60;
  return `${String(hours).padStart(2, "0")}:${String(mins).padStart(2, "0")}`;
}

function findViableGap(
  start: string,
  end: string,
  bookings: Booking[],
  recommendedtime: number
): string | null {
  const workingStart = timeToMinutes(start);
  const workingEnd = timeToMinutes(end);
  // Convert bookings to minutes and sort them by start time
  const convertedBookings = bookings
    .map((booking) => ({
      id: booking.id,
      start: timeToMinutes(moment(booking.startdate).utc().format("HH:mm")),
      end: timeToMinutes(moment(booking.enddate).utc().format("HH:mm")),
    }))
    .sort((a, b) => a.start - b.start);

  // If there are no bookings, check if the full working hours can fit the new booking
  if (convertedBookings.length === 0) {
    if (workingEnd - workingStart >= recommendedtime) {
      return minutesToTime(workingStart);
    }
    return null;
  }

  // Check for gaps, starting from the beginning of the working hours
  let previousEnd = workingStart;

  for (let i = 0; i < convertedBookings.length; i++) {
    const currentStart = convertedBookings[i].start;

    // Check the gap between `previousEnd` and the start of the current booking
    if (currentStart - previousEnd >= recommendedtime) {
      // Found a viable gap
      return minutesToTime(previousEnd); // Start the booking as early as possible
    }

    // Update the end of the last processed booking
    previousEnd = Math.max(previousEnd, convertedBookings[i].end);
  }

  // Check for a gap after the last booking until the end of the working day
  if (workingEnd - previousEnd >= recommendedtime) {
    return minutesToTime(previousEnd);
  }

  return null; // No viable gap found
}

/**
 * Check if a booking overlaps with a specific time range
 *
 * @param bookingStart - Booking start time in "HH:mm" format
 * @param bookingEnd - Booking end time in "HH:mm" format
 * @param rangeStart - Start time of the range (e.g., "09:00")
 * @param rangeEnd - End time of the range (e.g., "13:00")
 * @returns {boolean} - True if the booking overlaps with the range
 */
export function isBookingInBetween(
  startDate: string,
  endDate: string,
  rangeStart: string,
  rangeEnd: string
): boolean {
  // Parse the range times into Date objects on the same day as the booking
  const parseTimeOnSameDay = (baseDate: Date, time: string): Date => {
    const [hours, minutes] = time.split(":").map(Number);
    const date = new Date(baseDate);
    date.setUTCHours(hours, minutes, 0, 0); // Set hours and minutes in UTC
    return date;
  };

  const bookingStart = new Date(startDate);
  const bookingEnd = new Date(endDate);
  const rangeStartTime = parseTimeOnSameDay(bookingStart, rangeStart);
  const rangeEndTime = parseTimeOnSameDay(bookingStart, rangeEnd);

  // Check if the booking overlaps with the range
  return (
    (bookingStart >= rangeStartTime && bookingStart < rangeEndTime) || // Starts in the range
    (bookingEnd > rangeStartTime && bookingEnd <= rangeEndTime) || // Ends in the range
    (bookingStart <= rangeStartTime && bookingEnd >= rangeEndTime) // Covers the whole range
  );
}

export function lockBookingInList(message: any, list: Booking[]) {
  let newlist = list;
  if (message?.data?.bookingid) {
    if (message?.data && message.data?.bookingid && list?.length > 0) {
      const index = list.findIndex(
        (n: Booking) => n.id === message.data.bookingid
      );
      if (index >= 0) {
        newlist = [...list];
        list[index].locked = true;
        list[index].lockedby = message.data.lockedby;
      }
    }
  }
  return newlist;
}

export function unlockBookingInList(message: any, list: Booking[]): Booking[] {
  let newlist = list;
  if (message?.data?.bookingid) {
    if (message?.data && message.data?.bookingid && list?.length > 0) {
      const index = list.findIndex(
        (n: Booking) => n.id === message.data.bookingid
      );
      if (index >= 0) {
        newlist = [...list];
        list[index].locked = false;
        list[index].lockedby = "";
      }
    }
  }
  return newlist;
}

export async function processPvConfimrationResponse(
  booking: Booking,
  response: any,
  inspectorBookings: Booking[]
) {
  let errormessage = { message: "" };
  let swapbooking: Booking | undefined = undefined;

  if (response === "yes") {
    return (errormessage.message = "Invalid response! Please contact ACT team");
  } else if (response === "no") {
    return (errormessage.message = "Invalid response! Please contact ACT team");
  } else if (response === "callback") {
    return (errormessage.message = "Invalid response! Please contact ACT team");
  } else if (response === "option1") {
    // Tenant response - I will provide access between 9am-1:30pm, on [Date]
    booking.status = Booking.BOOKING_STATUS_BOOKED;
    booking.tenantattending = "yes";
    booking.keypickup = "Meet Tenant";
    booking.releasekeysto = "Meet Tenant";
    booking.confirmaccess = true;
    booking.slottype = "Morning";
    booking.inspector.bookings = inspectorBookings;
    const stime = pvDetermineViableSlot(booking, booking.inspector);
    let scheduledateAsDate = moment(booking.startDate).utc().toDate();
    const newstartdate = prependDate(
      stime,
      booking.startDate,
      scheduledateAsDate
    );
    swapbooking = inspectorBookings.find((b) => {
      const booked_index = Booking.BOOKING_STATUS_LIST.indexOf(
        Booking.BOOKING_STATUS_BOOKED
      );
      const status_index = Booking.BOOKING_STATUS_LIST.indexOf(b.status);
      return (
        b.jobtype === "property visit" &&
        status_index < booked_index &&
        b.startdate === newstartdate
      );
    });
    if (swapbooking) {
      swapbooking.startdate = booking.startdate;
      swapbooking.enddate = booking.enddate;
    }
    booking.startdate = prependDate(
      stime,
      booking.startDate,
      scheduledateAsDate
    );
    booking.enddate = moment(booking.startdate)
      .utc()
      .add(30, "minutes")
      .format(bookingdateformat);
    if (!booking.startdate || !booking.enddate) {
      return (errormessage.message =
        "There is no available slot for the given date");
    }

    if (booking.startdate && booking.enddate) {
      booking.appointmenttime = prependDate(
        "12:30 AM",
        booking.startDate,
        booking.startdateAsDate
      );
      booking.keypickup = "Meet Tenant";
      booking.keypickupfromaddress = new Address();
      booking.releasekeysto = "Key return is N/A";
      return { booking, swapbooking };
    }
  } else if (response === "option2") {
    // Tenant response - I will provide access between 1:30pm-6pm, on [Date]
    booking.status = Booking.BOOKING_STATUS_BOOKED;
    booking.tenantattending = "yes";
    booking.keypickup = "Meet Tenant";
    booking.releasekeysto = "Meet Tenant";
    booking.confirmaccess = true;
    booking.slottype = "Afternoon";
    booking.inspector.bookings = inspectorBookings;

    const stime = pvDetermineViableSlot(booking, booking.inspector);
    let scheduledateAsDate = moment(booking.startDate).utc().toDate();
    const newstartdate = prependDate(
      stime,
      booking.startDate,
      scheduledateAsDate
    );
    swapbooking = inspectorBookings.find((b) => {
      const booked_index = Booking.BOOKING_STATUS_LIST.indexOf(
        Booking.BOOKING_STATUS_BOOKED
      );
      const status_index = Booking.BOOKING_STATUS_LIST.indexOf(b.status);
      return (
        b.jobtype === "property visit" &&
        status_index < booked_index &&
        b.startdate === newstartdate
      );
    });
    if (swapbooking) {
      swapbooking.startdate = booking.startdate;
      swapbooking.enddate = booking.enddate;
    }
    booking.startdate = prependDate(
      stime,
      booking.startDate,
      scheduledateAsDate
    );
    booking.enddate = moment(booking.startdate)
      .utc()
      .add(30, "minutes")
      .format(bookingdateformat);
    if (!booking.startdate || !booking.enddate) {
      return (errormessage.message =
        "There is no available slot for the given date");
    }

    if (booking.startdate && booking.enddate) {
      booking.appointmenttime = prependDate(
        "12:35 AM",
        booking.startDate,
        booking.startdateAsDate
      );
      booking.keypickup = "Meet Tenant";
      booking.keypickupfromaddress = new Address();
      booking.releasekeysto = "Key return is N/A";
      return { booking, swapbooking };
    }
  } else if (response === "option3") {
    // Tenant response - You can obtain access via management keys from [Client], Flexi All Day
    booking.status = Booking.BOOKING_STATUS_BOOKED;
    booking.tenantattending = "no";
    booking.keypickup = "From Agency";
    booking.releasekeysto = "To Agency";
    booking.confirmaccess = false;
    if (!booking.emaillogs) booking.emaillogs = new EmailLogs();
    booking.emaillogs.tenantconfirmationreceiveddate = datetimeToUTC(
      new Date()
    );
    booking.appointmenttime = prependDate(
      "12:15 AM",
      booking.startDate,
      booking.startdateAsDate
    );
    return { booking, swapbooking };
  } else if (response === "option4") {
    // Tenant response - Approved access via concierge Flexi All Day
    booking.status = Booking.BOOKING_STATUS_BOOKED;
    booking.tenantattending = "no";
    booking.appointmenttime = prependDate(
      "12:15 AM",
      booking.startDate,
      booking.startdateAsDate
    );
    booking.keypickup = "Via Concierge";
    booking.keypickupfromaddress = new Address();
    booking.releasekeysto = "Via Concierge";
    booking.keyreleasetoaddress = new Address();
    booking.confirmaccess = false;
    booking.callbackrequested = true;
    if (!booking.emaillogs) booking.emaillogs = new EmailLogs();
    booking.emaillogs.tenantconfirmationreceiveddate = datetimeToUTC(
      new Date()
    );
    return { booking, swapbooking };
  }
}

export function findRecommendedTiming(booking: Booking,inspector: Inspector) {
  let timing = 0;
  if (inspector && inspector.schedules && booking.jobtype === "property visit") {
    const findInspectorSchedule = inspector.schedules.find(
      (f: Schedule) => f.reporttype.toLocaleLowerCase() === booking.jobtype
    );
    if (findInspectorSchedule) {
      let maintiming = findInspectorSchedule.timinggroups
        .flatMap(tg => tg.propertytypegroup || [])
        .find(ptg => ptg.propertytype === "default");
        if (maintiming) {
          timing = maintiming.timing || 0;
        }
    }
  }
  return timing;
}

/*export function initgooglecalendar(serviceaccountclientemail: string | undefined, serviceaccountprivatekey: string | undefined): any {
  const client = new JWT({
    email: serviceaccountclientemail,
    key: `-----BEGIN PRIVATE KEY-----\n${serviceaccountprivatekey}\n-----END PRIVATE KEY-----\n`,
    scopes: [ // set the right scope
      'https://www.googleapis.com/auth/calendar',
      'https://www.googleapis.com/auth/calendar.events',
    ],
  });

  const calendar = google.calendar({ version: 'v3' });

  return { client, calendar };
}*/
