<template>
    <div class="card bg-light booking-section">
      <div class="card-header booking-section-header" @click="expanded = !expanded">
        <div class="row">
          <div class="col-md-9"><h3 class="mb-0">Scheduling</h3></div>
          <div
            class="col-md-3 text-right"
            v-if="booking.appointmenttimeoutsidebooking"
          >
            <span class="alert-text blink_me" style="vertical-align: sub"
              >Booking time has changed. Resend notifications.</span
            >
          </div>
        </div>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-md-3">
            <form>
              <fieldset>
                <div class="form-group">
                  <label class="col-form-label">Who</label>
                  <div>
                    <singleselect-text
                      v-model="_inspector"
                      track-by="slug"
                      label="name"
                      :options="inspectorlist"
                      :allow-empty="true"
                      :taggable="false"
                      :disabled="disableddatewatch"
                      :ref="el => { dynamicRefs['inspectorDropDown'] = el }"
                      @open="dropDownOpened('inspectorDropDown')"
                      class="no-padding"
                      @search-change="inspectorsearchfilter = $event"
                    >
                      <template
                          #clear
                          v-if="_inspector && _inspector.slug"
                          >
                        <i @mousedown.prevent.stop="resetInspector"
                          class="mt-auto mb-auto multiselect__clear fa fa-times"
                          aria-label="Clear inspector"
                        ></i>
                      </template>
                      <template #singleLabel="props">
                        <div class="row">
                          <div class="col-md-6 mt-auto mb-auto pr-0 text-wrap" :class="getPiNameColClass(props.option)" >
                            {{ props.option.name }}
                          </div>
                          <div class="col-md-2 pl-1 pr-0 mt-auto mb-auto" >
                            <svg v-if="previousPI(props.option)" xmlns="http://www.w3.org/2000/svg" height="1.2em" viewBox="0 0 512 512">
                              <path d="M0 224c0 17.7 14.3 32 32 32s32-14.3 32-32c0-53 43-96 96-96H320v32c0 12.9 7.8 24.6 19.8 29.6s25.7 2.2 34.9-6.9l64-64c12.5-12.5 12.5-32.8 0-45.3l-64-64c-9.2-9.2-22.9-11.9-34.9-6.9S320 19.1 320 32V64H160C71.6 64 0 135.6 0 224zm512 64c0-17.7-14.3-32-32-32s-32 14.3-32 32c0 53-43 96-96 96H192V352c0-12.9-7.8-24.6-19.8-29.6s-25.7-2.2-34.9 6.9l-64 64c-12.5 12.5-12.5 32.8 0 45.3l64 64c9.2 9.2 22.9 11.9 34.9 6.9s19.8-16.6 19.8-29.6V448H352c88.4 0 160-71.6 160-160z"/>
                            </svg>
                          </div>
                          <div class="col-md-2 pl-1 pr-0 mt-auto mb-auto">
                            <span class="fa-stack fa-1x" v-if="getRating(props.option) > 0">
                              <i class="fas fa-star fa-stack-1x rating-start"></i>
                              <strong class="fa-stack-1x rating-text">{{ getRating(props.option) }}</strong>
                            </span>
                          </div>     
                        </div>
                      </template>
                      <template #option="props"
                        ><div
                          class="row"
                          :class="{
                            black: getRating(props.option) === 0,
                            red: getRating(props.option) === 1,
                            amber: getRating(props.option) == 2,
                            green: (getRating(props.option) > 2),
                            excluded: isPIExcludedOrPreferred(props.option) === 'excluded',
                            preferred: isPIExcludedOrPreferred(props.option) === 'preferred',
                          }"
                        >
                          <div class="col-md-4 mt-auto mb-auto pr-0 text-wrap">
                            {{ props.option.name }}
                          </div>
                          <div class="col-md-1 pl-1 pr-0 mt-auto mb-auto">
                            <svg v-if="previousPI(props.option)" xmlns="http://www.w3.org/2000/svg" height="1.2em" viewBox="0 0 512 512">
                              <path d="M0 224c0 17.7 14.3 32 32 32s32-14.3 32-32c0-53 43-96 96-96H320v32c0 12.9 7.8 24.6 19.8 29.6s25.7 2.2 34.9-6.9l64-64c12.5-12.5 12.5-32.8 0-45.3l-64-64c-9.2-9.2-22.9-11.9-34.9-6.9S320 19.1 320 32V64H160C71.6 64 0 135.6 0 224zm512 64c0-17.7-14.3-32-32-32s-32 14.3-32 32c0 53-43 96-96 96H192V352c0-12.9-7.8-24.6-19.8-29.6s-25.7-2.2-34.9 6.9l-64 64c-12.5 12.5-12.5 32.8 0 45.3l64 64c9.2 9.2 22.9 11.9 34.9 6.9s19.8-16.6 19.8-29.6V448H352c88.4 0 160-71.6 160-160z"/>
                            </svg>
                          </div>
                          <div class="col-md-2 pl-1 pr-0 mt-auto mb-auto">
                            <span class="fa-stack fa-1x" v-if="getRating(props.option) > 0">
                              <i class="fas fa-star fa-stack-1x rating-start"></i>
                              <strong class="fa-stack-1x rating-text">{{ getRating(props.option) }}</strong>
                            </span>
                          </div>
                          <div class="col-md-3 pl-1 pr-0 mt-auto mb-auto">
                            {{ inspectorAreacount(props.option) }}
                          </div>
                          <div class="col-md-2 pl-1 pr-0 mt-auto mb-auto">
                            {{ props.option.itype }}
                          </div>            
                        </div>
                      </template>
                    </singleselect-text>
                  </div>
                </div>
              </fieldset>
            </form>
          </div>
          <div class="col-md-4">
            <form>
              <fieldset>
                <div class="form-group">
                  <label class="col-form-label">Timing</label>
                  <div class="row">
                    <div class="col-md-5 pr-0">
                      <singleselect-text
                        v-model="_startdate"
                        :options="starttimeoptions"
                        :allow-empty="true"
                        :taggable="false"
                        :ref="el => { dynamicRefs['startDateDropDown'] = el }"
                        @open="dropDownOpened('startDateDropDown')"
                        :class="{
                          outline: booking.preferredduration > 0 || schedulealert,
                        }"
                        :disabled="disableddatewatch || disabledtime"
                      >
                        <template
                            #clear
                            v-if="_startdate"
                           >
                          <i @mousedown.prevent.stop="_startdate = ''"
                            class="multiselect__clear fa fa-times"
                            aria-label="Clear Start Time"
                          ></i>
                        </template>
                        <template #singleLabel="props"
                          ><div
                            class="row"
                            :class="{
                              'text-red': timeOutsideAgencyOpeningHours(props.option)
                            }"
                          >
                            <div class="col-md-12">
                              {{ customLabel(props.option) }}
                            </div>
                          </div>
                        </template>
                        <template #option="props"
                          ><div
                            class="row"
                            :class="{
                              'text-red': timeOutsideAgencyOpeningHours(props.option)
                            }"
                          >
                            <div class="col-md-12">
                              {{ customLabel(props.option) }}
                            </div>
                          </div>
                        </template>
                      </singleselect-text>
                    </div>
                    <div class="col-md-5 pl-0">
                      <singleselect-text
                        v-model="_enddate"
                        :options="endtimeoptions"
                        :allow-empty="true"
                        :taggable="false"
                        :disabled="disableddatewatch || disabledtime"
                        :ref="el => { dynamicRefs['endDateDropDown'] = el }"
                        @open="dropDownOpened('endDateDropDown')"
                        :class="{
                          outline: booking.preferredduration > 0 || schedulealert,
                        }"
                      >
                        <template 
                            #clear
                            v-if="_enddate"
                            >
                          <i @mousedown.prevent.stop="_enddate = ''; clearPreferredduration()"
                            class="multiselect__clear fa fa-times"
                            aria-label="Clear End Time"
                          ></i>
                        </template>
                        <template #singleLabel="props"
                          ><div
                            class="row"
                            :class="{
                              'text-red': timeOutsideAgencyOpeningHours(props.option)
                            }"
                          >
                            <div class="col-md-12">
                              {{ customLabel(props.option) }}
                            </div>
                          </div>
                        </template>
                        <template #option="props"
                          ><div
                            class="row"
                            :class="{
                              'text-red': timeOutsideAgencyOpeningHours(props.option)
                            }"
                          >
                            <div class="col-md-12">
                              {{ customLabel(props.option) }}
                            </div>
                          </div>
                        </template>
                      </singleselect-text>
                    </div>
                  </div>
                  <div class="row" v-if="booking.preferredduration > 0">
                    <div
                      class="col-md-10"
                      v-if="recommendedtime > booking.preferredduration"
                    >
                      Recommended time overridden. {{ _preferredduration }} short.
                    </div>
                    <div
                      class="col-md-10"
                      v-if="recommendedtime < booking.preferredduration"
                    >
                      Recommended time overridden. Additional
                      {{ _preferredduration }} given.
                    </div>
                  </div>
                </div>
              </fieldset>
            </form>
          </div>
          <div class="col-md-3">
            <form>
              <fieldset>
                <div class="form-group">
                  <label class="col-form-label">Recommended time</label>
                  <div class="row">
                    <div class="col-md-12">
                      <input
                        type="text"
                        class="form-control height-43"
                        :value="_recommendedtime"
                        disabled="true"
                      />
                    </div>
                  </div>
                </div>
              </fieldset>
            </form>
          </div>
  
          <div class="col-md-2">
            <form>
              <fieldset>
                <div class="form-group">
                  <label class="col-form-label">Appointment time</label>
                  <div class="row">
                    <div :class="{'col-md-12': _appointmenttime != 'No Tenant but Fixed Time', 'col-md-9 pr-0': _appointmenttime === 'No Tenant but Fixed Time'}">
                      <singleselect-text
                        v-model="_appointmenttime"
                        :options="appointmenttimeoptions"
                        :customLabel="customLabel"
                        :allow-empty="true"
                        :taggable="false"
                        :disabled="disableddatewatch"
                        :ref="el => { dynamicRefs['appointmentTimeDropDown'] = el }"
                        @open="dropDownOpened('appointmentTimeDropDown')"
                        :class="{
                          outline:
                            booking.preferredappointmenttime > 0 || schedulealert,
                        }"
                      >
                        <template 
                            #clear
                            v-if="_recommendedtime"
                            >
                          <i @mousedown.prevent.stop="
                        clearPreferredappointmenttime()
                            "
                            class="multiselect__clear fa fa-times"
                            aria-label="Clear Appointment Time"
                          ></i>
                        </template>
                        <template #singleLabel="props">
                          <div
                            :class="{
                              'text-green': isCustomerReportFlexOn(props.option),
                            }"
                          >
                            {{ props.option }}
                          </div>
                        </template>
                        <template #option="props">
                          <div
                            :class="{
                              'text-green': isCustomerReportFlexOn(props.option),
                            }"
                          >
                            {{ props.option }}
                          </div>
                        </template>
                      </singleselect-text>
                    </div>
                    <!-- <div class='col-md-3 pl-0 pr-0' v-if="_appointmenttime === 'No Tenant but Fixed Time'">
                      <div
                        @click="showFixedTimeModal()"
                        class="btn btn-outline-primary"
                        title=""
                      >
                        <i class="far fa-file-alt"></i>
                      </div>
                    </div> -->
                  </div>
                  <div class="row" v-if="booking.preferredappointmenttime > 0">
                    <div class="col-md-12">
                      Overriding appointment time
                      {{ recommendedappointmenttimeAsHHMM }}
                    </div>
                  </div>
                </div>
              </fieldset>
            </form>
          </div>
        </div>
      </div>

    </div>
  </template>
  
  <script lang="ts" setup>
  import { computed, ref, onMounted, inject, watch, nextTick, onBeforeUnmount, onUnmounted, } from 'vue';
  import { useStore } from 'vuex';
  import { useRoute, useRouter } from 'vue-router';
  import moment from 'moment-timezone';
  import _castArray from 'lodash/castArray';
  import _get from 'lodash/get';
  import Ably from "ably";
  import { Booking, Inspector, Report, Room, Schedule, Section, SelectOption, TravelTime, Type, Location, Customer, PresetError, DistanceMatrixResponse, DistanceMatrixRow, DistanceMatrixElement, TextValueObject } from '@/models';
  import defaultschedules from "@/store/inspectors/json/schedules.json";
  import { postfixTime } from "@/utilities";
  
  const store = useStore();
  const route = useRoute();
  const realtime: Ably.Realtime = inject('realtime');
  const actProperty: any = inject('actProperty'); // Injected property
  const channel = realtime.channels.get('diary');
  const deviceid = actProperty.getDeviceId();
  
  const expanded = ref(false);
  
  const inspectorsearchfilter = ref('');
  const starttimeoptions = ref([]);
  const endtimeoptions = ref([]);
  const appointmenttimeoptions = ref([]);
  
  const bookingFixedTimeModal = ref(null);
  const dynamicRefs = ref({});
  
  const booking = computed((): Booking => store.getters['diary/booking']);
  const customer = computed((): Customer => store.getters['diary/customer']);
  const inspectors = computed((): Inspector[] => store.getters['diary/inspectorlist']);
  const inspector = computed((): Inspector => store.getters['diary/inspector']);
  const startdate = computed(() => store.getters['diary/startdate']);
  const enddate = computed(() => store.getters['diary/enddate']);
  const recommendedappointmenttime = computed(() => store.getters['diary/recommendedappointmenttime']);
  const currentdate = computed(() => store.getters['diary/currentdate']);
  const recommendedtime = computed(() => store.getters['diary/recommendedtime']);
  const appointmenttime = computed(() => store.getters['diary/appointmenttime']);
  const setInspector = (inspector) => {
    store.commit('diary/setInspector', inspector);
  };

  const disableddatewatch = computed(() => {
    return moment(currentdate.value).format("YYYY-MM-DD") === '2050-12-31' ? true : false;
  });
  const disabledtime = computed(() => {
    return _appointmenttime.value === 'Flexi 9 till 1.30' || _appointmenttime.value === 'Flexi 1.30 till 6';
  });
  const setStateStartdate = (startdate) => {
    store.commit('diary/setStartdate', startdate);
  };
  
  const setStateEnddate = (enddate) => {
    store.commit('diary/setEnddate', enddate);
  };
  
  const setStateRecommendedtime = (recommendedtime) => {
    store.commit('diary/setRecommendedtime', recommendedtime);
  };
  const saveBooking = async (booking: Booking): Promise<Booking> => {
    return await store.dispatch('diary/saveBooking', booking);
  };
  const publishNewbookingdetails = () => {
    // Step 1 - calculating the travel time for a new booking
    // Filtering criteria
    // Only PIs who are 3 star for the given postcode prefix of the new booking, exclude 2 and 1 star
    // If the PI did the previous linked report, even if not 3 star
    // If the PI already has bookings in that postcode area, even if not 3 star
    // If the above filtering provides no responses, then open up criteria to include 2 star.
    let toppilist: string[] =  [];
    for(let i = 0; i < inspectorlist.value.length; i++) {
      const selectoption = inspectorlist.value[i];
      let rating = getRating({slug:selectoption.slug});
      if(i < 6 || rating === 3) {
        const insp = inspectors.value.find((i)=> i.id === selectoption.slug);
        if(insp) {
          toppilist.push(insp.id);
        }
      }
    }
  
    let formcurrentdate = moment(currentdate.value).utc().format("YYYY-MM-DD");
    channel.publish('newbookingdetails', 
      { deviceid: deviceid,
        newbookingid: booking.value.id, 
        formcurrentdate: formcurrentdate,
        recommendedtime: recommendedtime.value, 
        startpostcodes: booking.value.startpostcodes, 
        endpostcodes:  booking.value.endpostcodes,
        toppilist: toppilist,
      });
  }
  
  const setStateAppointmenttime = (appointmenttime) => {
    store.commit('diary/setAppointmenttime', appointmenttime);
  };
  
  const setBookingDeep = async (payload): Promise<any> => {
    return await store.dispatch('diary/setBookingDeep', payload);
  };
  
  const sortInpectorsInState = async (params): Promise<any> => {
    return await store.dispatch('diary/sortInpectors', params);
  };
  
  onMounted(() => {
    Promise.all([setStartTimeOptions(), setEndTimeOptions()])
      .then((values) => {})
      .catch((err) => {
        actProperty.displayError(err);
      });
    
    channel.subscribe('commoncovertapplied', (message: any) => {
      if(message?.data) {
        // Make sure the event is coming from the same pc
        if(message.data.deviceid != deviceid) return;
        if(message.data.bookingid != booking.value.id) return;
        // Sort inspectors based on previous booking selection
        sortInspectors();
      }
    });
  });
  
  onBeforeUnmount(() => {
    channel.unsubscribe('commoncovertapplied');
  });
  
  const sortInspectors = () => {
    sortInpectorsInState({
      previousreport: booking.value?.basereport,
      previousbooking: booking.value?.previousbooking,
      postcode: booking.value?.address?.postcode,
    });
  }
  
  const inspectorlist = computed((): SelectOption[] => {
    return inspectors.value
      .filter((i: Inspector) => !i.inactive && i.name.toLowerCase() != "default")
      .filter((i: Inspector) => {
        if(inspectorsearchfilter.value)
          return i.name.toLowerCase().startsWith(inspectorsearchfilter.value.toLowerCase());
        else
          return true;
      })
      .map((i) => new SelectOption({ name: i.name, slug: i.id, itype: i.itype }));
  });
  
  const _inspector = computed({
    get: () => {
      let insp = inspectors.value.find((i) => i.id == inspector.value.id);
      return new SelectOption({ name: insp?.name, slug: insp?.id });
    },
    set: (val: SelectOption) => {
      if (val) {
      let insp = inspectors.value.find((i) => i.id == val.slug);
      if (insp) {
        setInspector(insp);
      }
    }
    }
  });
  
  const resetInspector = () => {
    setInspector(new Inspector());
  }
  
  const setStartTimeOptions = () => {
    starttimeoptions.value = [];
    let startmoment = moment().set("hour", 6).set("minute", 0);
    appointmenttimeoptions.value.push("Flexi all day");
    appointmenttimeoptions.value.push("Flexi 9 till 1.30");
    appointmenttimeoptions.value.push("Flexi 1.30 till 6");
    for (let i = 0; i < 62; i++) {
      const hourMinutes = startmoment.format("hh:mm A");
      starttimeoptions.value.push(hourMinutes);
      appointmenttimeoptions.value.push(hourMinutes);
      startmoment.add(15, "minutes");
    }
  }
  
  const setEndTimeOptions = () => {
    endtimeoptions.value = [];
    let startmoment = moment().set("hour", 6).set("minute", 0);
    if (_startdate.value) {
      startmoment = moment(_startdate.value, "hh:mm A");
      startmoment.add(15, "minutes");
    }
    for (let i = 0; i < 62; i++) {
      const hourMinutes = startmoment.format("hh:mm A");
      endtimeoptions.value.push(hourMinutes);
      startmoment.add(15, "minutes");
      if (hourMinutes === "9:00 PM ") break;
    }
  };
  
  const _startdate = computed({
    get: () => {
      let value = startdate.value
        ? moment(startdate.value).utc().format("hh:mm A")
        : "";
      const index = starttimeoptions.value.findIndex(
        (option: string) => option === value
      );
      if (index < 0) value = "";
      return value;
    },
    set: (val: string) => {
      setStateStartdate(
        actProperty.prependDate(val, startdate.value, currentdate.value)
      );
      determineEnddate();
  
      // Only determine appointmenttime for a new booking
      if(!booking.value.appointmentconfirmationsent) determineAppointmenttime();
    }
  });
  
  
  watch(() => _startdate.value, (newVal) => {
    setEndTimeOptions();
  });
  
  const _enddate = computed({
    get: () => {
      let value = enddate.value
        ? moment(enddate.value).utc().format("hh:mm A")
        : "";
      const index = endtimeoptions.value.findIndex(
        (option: string) => option === value
      );
      if (index < 0) value = "";
      return value;
    },
    set: (val: string) => {
      setStateEnddate(
        actProperty.prependDate(val, startdate.value, currentdate.value)
      );
      if(!startdate.value || actProperty.isFiveam(startdate.value))
        determineStartdate();
      let duration = booking.value.duration;
      if (duration === recommendedtime.value) duration = 0;
      setBookingDeep({
        path: "preferredduration",
        data: duration,
      });
      // Only determine appointmenttime for a new booking
      if(!booking.value.appointmentconfirmationsent) {      
        determineAppointmenttime();
      }
    }
  });
  
  const clearPreferredduration = () => {
    setBookingDeep({
      path: "preferredduration",
      data: 0,
    });
  
    if(!actProperty.isFiveam(startdate.value)) {
      determineEnddate();
    }
    else {
      setStateEnddate(actProperty.getFilveam());
    }
  }
  
  const determineStartdate = () => {
    if (enddate.value && _recommendedtime.value) {
      setStateStartdate(
        actProperty.prependDate(
          moment(enddate.value).utc().subtract(recommendedtime.value, "minutes").format("hh:mm A"),
          enddate.value,
          currentdate.value
        )
      );
    }
  }
  
  const determineEnddate = () => {
    if(bypassautodetermination.value) {
      return;
    }
    if(booking.value.jobtype === 'property visit' && !isNewBooking()){
      return;
    }
  
    if (startdate.value && _recommendedtime.value) {
      let time =
        booking.value.preferredduration > 0
          ? booking.value.preferredduration
          : recommendedtime.value;
      setStateEnddate(
        actProperty.prependDate(
          moment(startdate.value).utc().add(time, "minutes").format("hh:mm A"),
          startdate.value,
          currentdate.value
        )
      );
    }
  }
  
  const determineAppointmenttime = () => {
    // if(bypassautodetermination.value) {
    //   return;
    // }
  
    // // If in absence flag is on for this customer then do not auto determine appointment time
    // let inabs = (booking.value.customer?.policies?.inspectioninabs);
    // if(inabs) return;
  
    // let appointmenttime = "";
    // let timeinminutes = 0;
    // if (booking.value.preferredappointmenttime > 0)
    //   timeinminutes = booking.value.preferredappointmenttime;
    // else if (recommendedappointmenttime.value > 0)
    //   timeinminutes = recommendedappointmenttime.value;
  
    // // Round it to the nearest 15 minutes interval
    // timeinminutes = Math.round(timeinminutes / 15) * 15;
    // if (enddate.value && timeinminutes > 0) {
    //   appointmenttime = moment(enddate.value)
    //     .utc()
    //     .subtract(timeinminutes, "minutes")
    //     .format("hh:mm A");
    // } else if (startdate.value) {
    //   appointmenttime = moment(startdate.value).utc().format("hh:mm A");
    // }  
    // setStateAppointmenttime(
    //   actProperty.prependDate(
    //     appointmenttime,
    //     startdate.value,
    //     currentdate.value
    //   )
    // );
  }
  
  // If new booking - do not bypass
  // If flexi or Master with a PREP or startdatenotset - bypass
  const bypassautodetermination = computed(() => {
    if(isNewBooking()) return false;
    let prepfound = false;
    let sub: Booking | undefined = undefined;
    if (booking.value.subbookings?.length) {
      sub = booking.value.subbookings.find(
        (b: Booking) => b.subtype === Booking.PREP
      );
    }
    prepfound = sub?.id ? true : false;
    let flexi = (_appointmenttime.value === 'No Tenant but Fixed Time' 
      || _appointmenttime.value.startsWith('Flexi'));
  
    let startdatenotset = actProperty.isFiveam(startdate.value);
    return prepfound || flexi || startdatenotset;
  });
  
  const _recommendedtime = computed(() => {
    return convertToHoursMinutes(recommendedtime.value);
  });
  
  const _preferredduration = computed(() => {
    if (recommendedtime.value > booking.value.preferredduration) {
      return convertToHoursMinutes(
        recommendedtime.value - booking.value.preferredduration
      );
    } else {
      return convertToHoursMinutes(
        booking.value.preferredduration - recommendedtime.value
      );
    }
  });
  
  const convertToHoursMinutes = (val: number) => {
    let hourMinutes = moment
      .utc()
      .startOf("day")
      .add(val, "minutes")
      .format("HH [Hours] mm [Minutes]");
    hourMinutes = hourMinutes.replaceAll("00 Hours", "");
    hourMinutes = hourMinutes.replaceAll("00 Minutes", "");
    return hourMinutes;
  }
  
  const _appointmenttime = computed({
    get: (): string => {
      let time =
        booking.value.preferredappointmenttime > 0
          ? booking.value.preferredappointmenttime
          : appointmenttime.value;
      let apptime = time
        ? moment(appointmenttime.value).utc().format("hh:mm A")
        : "";
      
      if (apptime === "12:15 AM") apptime = "Flexi all day";
      if (apptime === "12:30 AM") apptime = "Flexi 9 till 1.30";
      if (apptime === "12:35 AM") apptime = "Flexi 1.30 till 6";
      const index = appointmenttimeoptions.value.findIndex(
        (option: string) => option === apptime
      );
      if (index < 0) apptime = "";
      return apptime;
    },
    set: async (val: string) => { 
      if (val === "Flexi all day") {
        setBookingDeep({
          path: "preferredappointmenttime",
          data: 0,
        });
        setStateAppointmenttime(
          actProperty.prependDate(
            "12:15 AM",
            startdate.value,
            currentdate.value
          )
        );
      } else if (val === "Flexi 9 till 1.30") {
        setBookingDeep({
          path: "preferredappointmenttime",
          data: 0,
        });
        setStateAppointmenttime(
          actProperty.prependDate(
            "12:30 AM",
            startdate.value,
            currentdate.value
          )
        );    
      } else if (val === "Flexi 1.30 till 6") {
        setBookingDeep({
          path: "preferredappointmenttime",
          data: 0,
        });
        setStateAppointmenttime(
          actProperty.prependDate(
            "12:35 AM",
            startdate.value,
            currentdate.value
          )
        ); 
      } else {
        if (!val) val = "0:00 AM";
        let startdateMoment = moment(startdate.value).utc();
        let enddateMoment = moment(enddate.value).utc();
        let selectedTime = postfixTime(
          enddate.value,
          moment(val, "hh:mm A").format(actProperty.bookingdateutcformat)
        );
        let selectetimeMoment = moment(selectedTime).utc();
        let inmiutesfromenddate = moment
            .duration(enddateMoment.diff(selectetimeMoment))
            .asMinutes();
  
        if(booking.value.id) {        
          // For an existing booking just set the time
          setBookingDeep({
            path: "preferredappointmenttime",
            data: inmiutesfromenddate,
          });
          setBookingDeep({
            path: "appointmenttime",
            data: actProperty.prependDate(val, startdate.value)
          });
          if(booking.value.jobtype === 'property visit') {
            setStateStartdate(
              actProperty.prependDate(val, startdate.value, currentdate.value)
            );
            let endDate =  moment(startdate.value).utc().add(30, "minutes").format(actProperty.bookingdateformat);
            setBookingDeep({
              path: "enddate",
              data: endDate
            });
          }
        }
        else {
          // For a new appointment rely on determineAppointmenttime
          let inmiutesfromstartdate = moment
            .duration(startdateMoment.diff(selectetimeMoment))
            .asMinutes();
  
          // If reccommendedtime is selected, then just reset override
          // if time before starttime is selected, don't allow
          if (inmiutesfromstartdate <= 0 && inmiutesfromenddate >= 0) {
            if (inmiutesfromenddate === recommendedappointmenttime.value)
              inmiutesfromenddate = 0;
            setBookingDeep({
              path: "preferredappointmenttime",
              data: inmiutesfromenddate,
            });
            determineAppointmenttime();
          }
        }
      }
    }
  });
  

  
  const recommendedappointmenttimeAsHHMM = computed((): string => {
    let appointmenttime: string = "";
    if (enddate.value && recommendedappointmenttime.value > 0) {
      appointmenttime = moment(enddate.value)
        .utc()
        .subtract(recommendedappointmenttime.value, "minutes")
        .format("hh:mm A");
    } else if (startdate.value) {
      appointmenttime = moment(startdate.value).utc().format("hh:mm A");
    }
    return appointmenttime;
  });
  
  const clearPreferredappointmenttime = () => {
    setBookingDeep({path: "preferredappointmenttime",data: 0,});
    //determineAppointmenttime();
    setStateAppointmenttime('');
  }
  
  watch(() => recommendedappointmenttime.value, () => {
    if (!actProperty.isFiveam(startdate.value) && !actProperty.isFiveam(enddate.value)) {
      if (booking.value.preferredduration === 0) determineEnddate();
      if (!booking.value.appointmentconfirmationsent && booking.value.preferredappointmenttime === 0) {
        determineAppointmenttime();
      }
    }
  });
  
  const bookingwatch = computed(() => {
    let value = `${booking.value.inspector.id},${booking.value.internaljobtype},${booking.value.startdate},${booking.value.propertytypegroup}`;
    if (booking.value?.basereport) {
      if (booking.value?.basereport?.firstexportedby)
        value = `${value},${booking.value?.basereport.firstexportedby}`;
      if (booking.value?.basereport.date)
        value = `${value},${booking.value.basereport.date}`;
    }
    return value;
  });
  
  watch(() => bookingwatch.value, () => {
    // setStateRecommendedtime(0);
    // determineRecommendedtime();
  
    if (booking.value.preferredduration === 0 && !actProperty.isFiveam(startdate.value)) determineEnddate();
  
    // Following code is adding a lot of complexity
    // Uncommeting for now to see if it works!
    // if (!actProperty.isFiveam(startdate.value) && !actProperty.isFiveam(enddate.value)) {
    //   if (!booking.value.appointmentconfirmationsent && booking.value.preferredappointmenttime === 0) {
    //     determineAppointmenttime();
    //   }
    // }
  });
  
  const determineRecommendedtime = () => {  
    // If this is a CO - CO off ACT Report
    // User previous report itemcount rule to determine recommendedtime
    // Trello - https://trello.com/c/qN6GnHHP/554-timing-of-jobs
    if(booking.value.jobtype === 'checkout' 
      && booking.value.internaljobtype === 'Check-Out - off ACT report'
      && basereportitemcount.value > 0) {
        let timeinminutes =Math.round(basereportitemcount.value * 0.6)
        timeinminutes = Math.ceil(timeinminutes / 15) * 15;
        setStateRecommendedtime(timeinminutes);
    }
    else if (booking.value.inspector) {
      let inspectorschedules: Schedule[] = [];
      if (
        !booking.value.inspector.schedules ||
        booking.value.inspector.schedules.length == 0
      ) {
        inspectorschedules = _castArray(
          _get(defaultschedules, "schedules", [])
        ).map((r: any) => new Schedule(r));
      } else {
        inspectorschedules = booking.value.inspector.schedules;
      }
      let filtereddefaultschedules = defaultschedules.schedules.filter(
        (schedule) => {
          let i = schedule.internaljobtypelist?.findIndex(
            (t) => t === booking.value.internaljobtype
          );
          return i >= 0 ? true : false;
        }
      );
      if (filtereddefaultschedules.length) {
        let searchreoprttype: string = "";
        if (filtereddefaultschedules.length == 1) {
          searchreoprttype = filtereddefaultschedules[0].reporttype;
        } else if (filtereddefaultschedules.length > 1) {
          let offactreport = false;
          let bysamepi = false;
          if (booking.value?.basereport?.id || booking.value?.previousbooking?.id) {
            offactreport = true;
            if (
              booking.value?.basereport?.firstexportedby &&
              booking.value?.basereport?.firstexportedby ===
                booking.value.inspector.email
            ) {
              bysamepi = true;
            }
            else if(booking.value?.previousbooking?.inspector &&
              booking.value?.previousbooking?.inspector?.id ===
                booking.value.inspector.id) {
              bysamepi = true;
            }
  
            let postfix = "";
            if (offactreport) postfix = " - off ACT report";
  
            let offupload =
              booking.value.internaljobtype === "Check-In - off upload";
            if (offupload) postfix = " - off upload";
  
            if (bysamepi) postfix = `${postfix} - same PI as at CO`;
  
            let reportdate = '';
            if(booking.value?.basereport?.id) reportdate = booking.value?.basereport.date;
            else if(booking.value?.previousbooking?.id) reportdate = booking.value?.previousbooking.bookingdate;
            let startdate = booking.value.startdate;
            if (!startdate)
              startdate = moment(currentdate.value)
                .utc()
                .format(actProperty.bookingdateformat);
            const date1: any = moment(
              reportdate,
              actProperty.bookingdateformat
            )
              .utc()
              .startOf("day");
            const date2: any = moment(
              startdate,
              actProperty.bookingdateformat
            )
              .utc()
              .startOf("day");
  
            // 1+ month ago
            if (
              moment(date2).subtract(1, "months").isSameOrAfter(moment(date1))
            ) {
              searchreoprttype = `Check-In following Check-Out 1+ month ago${postfix}`;
            }
            // less than 1 month greater than 48 hours ago
            else if (
              moment(date2).subtract(1, "months").isBefore(moment(date1)) &&
              moment(date2).subtract(2, "days").isSameOrAfter(moment(date1))
            ) {
              searchreoprttype = `Check-In following Check-Out less than 1 month greater than 48 hours ago${postfix}`;
            }
            // less than 48 hours ago
            else if (
              moment(date2).subtract(2, "days").isBefore(moment(date1)) &&
              moment(date2).subtract(1, "days").isSameOrAfter(moment(date1))
            ) {
              searchreoprttype = `Check-In following Check-Out less than 48 hours ago${postfix}`;
            }
            // less than 24 hours ago
            else if (
              moment(date2).subtract(1, "days").isBefore(moment(date1))
            ) {
              searchreoprttype = `Check-In following Check-Out less than 24 hours ago or following an Inventory Prep - off ACT report or upload`;
              if (bysamepi)
                searchreoprttype =
                  searchreoprttype + " and same PI";
            }
          }
        }      
        if(!searchreoprttype && booking.value.jobtype === 'checkin') {        
          // In case where we could not determine the schedule grouping for CI
          // we are to use the first (worst case scenario) as our default grouping
          searchreoprttype = 'Check-In following Check-Out 1+ month ago - off ACT report';
        }
  
        if (searchreoprttype) {
          var schedule: Schedule | undefined = inspectorschedules.find(
            (s) => s.reporttype === searchreoprttype
          );
          if(!schedule) {
            const scheduleObj: any = defaultschedules.schedules.find(
              (s) => s.reporttype === searchreoprttype
            );
            schedule = new Schedule(scheduleObj);
          }
          if (schedule && schedule.timinggroups) {
            const propertytypegroup = booking.value.propertytypegroup;
            if (propertytypegroup) {
              schedule.timinggroups.forEach((tg) => {
                if (tg.propertytypegroup) {
                  var timing = tg.propertytypegroup.find((ptg) => {
                    // This is to correct an unfortunate spelling mistake made in the default schedulers
                    const type = ptg.propertytype.replaceAll(
                      "Frunished",
                      "Furnished"
                    );
                    return type === propertytypegroup;
                  });
  
                  if(!timing) {
                    // Search in default
                    timing = tg.propertytypegroup.find((ptg) => ptg.propertytype === 'default');
                  }
  
                  if (timing) {
                    let rectime = timing.timing;
                    let itemcount = basereportitemcount.value;
                    if(timing.minutesperitem &&  itemcount > 0) {
                      rectime = (itemcount/100) * timing.minutesperitem;
                      // Round to the next 15 mins
                      rectime = Math.round(rectime / 15) * 15;
                      if(timing.tenanttoarriveafter) {
                        rectime += timing.tenanttoarriveafter;
                      }
                    }
                    setStateRecommendedtime(rectime);
  
                    // For check out recommendedappointmenttime is always 0
                    let _recommendedappointmenttime = 0;
                    if (booking.value.jobtype != "check out" && booking.value.jobtype != "soc")
                      _recommendedappointmenttime = timing.tenanttoarriveafter;
                    setBookingDeep({
                      path: "recommendedappointmenttime",
                      data: _recommendedappointmenttime,
                    });
                  }
                }
              });
            }
          }
        }
      }
    }
  }
  
  const basereportitemcount = computed(() => {
    let itemcount = 0;
    if (booking.value?.basereport && booking.value?.basereport)
      itemcount = countitems(booking.value?.basereport);
    else if (booking.value?.dataentryreport && booking.value?.dataentryreport)
      itemcount = countitems(booking.value?.dataentryreport);
    return itemcount;
  });
  const countitems = (report: Report) => {
    let itemcount = 0;
    report.rooms.forEach((r: Room) => {
      r.sections.forEach((s: Section) => {
        s.types.forEach((t: Type) => {
          itemcount += t.items.length;
        });
      });
    });
    return itemcount;
  }
  
  const ratingmap: Map<string, Map<string, number>> = new Map<string, Map<string, number>>();
  const getRating = (option: any): number => {
    let inspector: Inspector | undefined = undefined;
    if (option && option.slug) {
      inspector = inspectors.value.find((i: Inspector) => i.id === option.slug);
    }
    let rating: number = 0;
    if (
      inspector &&
      inspector.locations &&
      inspector.locations.length &&
      booking.value?.address?.postcode
    ) {
      let postcode = booking.value.address.postcode;
      postcode = postcode.replace(/ /g, "");
      let inspratingmap: Map<string, number> = ratingmap.get(inspector.id);
      if(inspratingmap) {
        let rating = inspratingmap.get(postcode);
        if(rating >= 0) {
          return rating;
        }
      }
      let areacode = postcode.substring(0, postcode.length - 3);
      if (areacode) {
        let location: Location | undefined = inspector.locations.find(
          (l: Location) => l.code.trim() === areacode.trim()
        );
        if (location) rating = location.rating;
      }
      if(!inspratingmap) {
        inspratingmap = new Map<string, number>();
        ratingmap.set(inspector.id, inspratingmap);
      }
      inspratingmap.set(postcode, rating);
    }
    return rating;
  }
  
  const previousPI = (option: any): boolean => {
    let inspector: Inspector | undefined = undefined;
    if (option && option.slug) {
      inspector = inspectors.value.find((i: Inspector) => i.id === option.slug);
    }
    let previousreportdonebysamepi: boolean = false;
    if (
      inspector &&
      booking.value?.basereport &&
      (booking.value?.basereport?.firstexportedby || booking.value?.basereport?.user)
    ) {
      previousreportdonebysamepi = (inspector.email === booking.value?.basereport?.firstexportedby || inspector.email === booking.value?.basereport?.user);
    }
    let previousbookingdonebysamepi: boolean = false;
    if (
      inspector &&
      booking.value?.previousbooking &&
      (booking.value?.previousbooking?.inspector?.id)
    ) {
      const previousbookingmoment = moment(booking.value.previousbooking.startdate).utc();
      const bookingmoment = moment(booking.value.startdate).utc();
      if(previousbookingmoment.isBefore(bookingmoment))
        previousbookingdonebysamepi = inspector.id === booking.value?.previousbooking?.inspector?.id;
    }
    return previousreportdonebysamepi || previousbookingdonebysamepi;
  }
  
  const getPiNameColClass = (option: any) => {
    let rating = getRating(option);
    let previous = previousPI(option);
    let columns = 11;
    if (rating) columns -= 4;
    if (previous) columns -= 1;
  
    let st = `col-md-${columns}`;
    const excluded = isPIExcludedOrPreferred(option);
    if(excluded) st = `${st} ${excluded}`;
  
    return st
  }
  
  const inspectorAreacount = (option: any): string => {
    let inspector: Inspector | undefined = getInspector(option);
    let count: number = 0;
    if (inspector) {
      count = inspector.getAreacount(booking.value?.address?.postcode);
    }
    return count > 0 ? `In area x ${count}` : "";
  }
  
  const getInspector = (option: any): Inspector => {
    let inspector: Inspector | undefined = undefined;
    if (option && option.slug) {
      inspector = inspectors.value.find((i: Inspector) => i.id === option.slug);
    }
    return inspector;
  }
  
  const schedulealert = computed(() => {
    return booking.value.propertytypegroup && !recommendedtime.value;
  });
  
  const dropDownOpened = (ref: string) => {
    nextTick(() => {
      // Get the reference to the dropdown list container
      const multiselect = dynamicRefs.value[ref] as any;
      const dropdownList = multiselect.$el.querySelector('.multiselect__content-wrapper');
  
      // Get the reference to the selected option element
      const selectedOption = multiselect.$el.querySelector('.multiselect__option--selected');
  
      // Scroll to the position of the selected element
      if(selectedOption?.offsetTop)
        dropdownList.scrollTop = selectedOption.offsetTop - 120;
      else if(ref === 'startDateDropDown' || ref === 'endDateDropDown')
        dropdownList.scrollTop = 480;
  
  
      if(ref === 'inspectorDropDown') {
        const highlightedOption = multiselect.$el.querySelector('.multiselect__option--highlight');
        if(highlightedOption) {
          highlightedOption.classList.remove('multiselect__option--highlight');
        }
      }
    });
  }
  
  const showFixedTimeModal = () => {
    const modal = bookingFixedTimeModal.value as any;
    modal.init();
    
    modal.show();
  }
  const isNewBooking = () => {
    return route.params.id == "new";
  }
  
  const customLabel = (option: string) => {
    return option.replace(/^0/, '');
  }
  
  const isCustomerReportFlexOn = (option: string) => {
    // The setting is only applicable if Inspection in absence is on for selected customer
    if(!customer.value.policies.inspectioninabs) return false;
  
    let result = false;
    if (option === 'Flexi all day') {
      result = ((booking.value.jobtype === 'inventory' || booking.value.jobtype === 'checkin') && customer.value.policies.invciflex)
        || ((booking.value.jobtype === 'checkout' || booking.value.jobtype === 'soc') && customer.value.policies.cosocflex);
    }
    else if(option === 'Flexi AM') {
      result = ((booking.value.jobtype === 'inventory' || booking.value.jobtype === 'checkin') && customer.value.policies.invciflexam);
    }
    else if(option === 'No Tenant but Fixed Time') {
      result = true;
    }
    return result;
  }
  
  const isPIExcludedOrPreferred = (option: SelectOption) => {
    let inspector: Inspector | undefined = undefined;
    if (option && option.slug) {
      inspector = inspectors.value.find((i: Inspector) => i.id === option.slug);
    }
    if (
      inspector &&
      inspector.excludedclients &&
      inspector.excludedclients.length
    ) {
      const excludedindex = inspector.excludedclients.findIndex((c) => c.id === customer.value.id);
      if(excludedindex >= 0) return 'excluded';
    }
    if (
      inspector &&
      inspector.preferredclients &&
      inspector.preferredclients.length
    ) {
      const preferredindex = inspector.preferredclients.findIndex((c) => c.id === customer.value.id);
      if(preferredindex >= 0) return 'preferred';
    }
    return '';
  }
  
  const timeOutsideAgencyOpeningHours = (option: string) => {
    if(!customer.value.policies.openinghours?.length) return false;
    
    const dow = moment(booking.value.startdate).utc().day();
    const agencyclosed = customer.value.policies?.openinghours[dow-1]?.closed;
    const agencyopenfrom = customer.value.policies?.openinghours[dow-1]?.from;
    const agencyopento = customer.value.policies?.openinghours[dow-1]?.to;
    if(agencyclosed) return true;
    if (
      (agencyopenfrom &&
        agencyopento &&
        (moment.utc(option, 'hh:mm A').isBefore(moment.utc(agencyopenfrom, 'HH:mm'))) ||
        moment.utc(option, 'hh:mm A').isAfter(moment.utc(agencyopento, 'HH:mm')))
    ) {
      return true
    }
    return false;
  }
  
  const piselectionwatch = computed(() => {
    return `${currentdate.value},
      ${recommendedtime.value},
      ${booking.value.startpostcodes},
      ${booking.value.endpostcodes}`;
  });
  watch(() => piselectionwatch.value, async () => {
    if(booking.value.id) return;
    if(recommendedtime.value && booking.value.address.postcode)
      publishNewbookingdetails();
  });
  </script>
  
  
  <style scoped lang="scss">
  @import "@/assets/sass/bootstrap/_variables.scss";
  
  .context-message {
    color: #a4a4a4;
  }
  h5 {
    color: #686868;
  }
  .booking-info {
    color: #253746;
  }
  .multiselecttag {
    position: relative;
    padding: 4px 10px 4px 10px;
    border-radius: 5px;
    color: #dbd55a;
    line-height: 1;
    background: #253746;
    margin-bottom: 5px;
    white-space: nowrap;
    overflow: hidden;
    max-width: 100%;
    text-overflow: ellipsis;
  }
  
  .multiselecttags {
    display: block;
    font-size: 14px;
  }
  
  *[data-tooltip] {
    position: relative;
    font-family: Font Awesome 5 Free Solid, Font Awesome 5 Free Regular;
  }
  
  *[data-tooltip]::after {
    content: attr(data-tooltip);
  
    position: absolute;
    top: -40px;
    left: -30px;
  
    pointer-events: none;
    opacity: 0;
    -webkit-transition: opacity 0.15s ease-in-out;
    -moz-transition: opacity 0.15s ease-in-out;
    -ms-transition: opacity 0.15s ease-in-out;
    -o-transition: opacity 0.15s ease-in-out;
    transition: opacity 0.15s ease-in-out;
  
    border-radius: 4px;
    width: 80px;
    background: #000000;
    color: #ffffff;
    display: block;
    font-size: 14px;
    line-height: 30px;
    padding: 5px 5px;
    text-align: center;
    font-family: Arial !important;
  }
  
  *[data-tooltip]:hover::after {
    opacity: 1;
  }
  
  .text-yellow {
    color: #e9db4b;
  }
  .text-red {
    color: orangered;
  }
  .text-green {
    color: #41b883;
    font-weight: bold;
  }
  .alert-text {
    color: $act-yellow;
  }
  
  .red {
    color: orangered;
  }
  
  .black {
    color: black;
  }
  
  .amber {
    color: orange;
  }
  
  .green {
    color: green;
  }
  
  .excluded {
    color: orangered;
    text-decoration: line-through;
  }
  
  .preferred {
    color: #41b883;
    font-weight: bold;
  }
  
  .multiselect__option--selected .text-green{
      color: #35495e;
      background: #41b883;
  }
  
  .multiselect__option--selected .text-red{
      color: orangered;
  }
  
  .multiselect__option--selected .excluded {
    color: orangered;
    background: #41b883;
  }
  .multiselect__option--highlight .excluded {
    color: #35495e;
    background-color: orangered;
  }
  .multiselect__option--highlight .text-red {
    color: orangered;
  }
  
  .multiselect__option--selected .preferred {
    color: #35495e;
    background: #41b883;
  }
  
  .no-padding ::v-deep .multiselect__option {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    min-height: 0px !important;
    line-height: 0px !important;
    width: 400px !important;
  }
  
  .booking-section ::v-deep .multiselect__option--highlight {
    color: #35495e;
    background: #f8f9fa;
  }
  
  .booking-section ::v-deep .multiselect__option--selected {
    color: #35495e;
    background: #41b883;
  }
  </style>
  
  <style lang="scss">
  .blink_me {
    animation: blinker 1s linear infinite;
  }
  
  @keyframes blinker {
    50% {
      opacity: 0;
    }
  }
  
  .train-icon {
    background-image: url("~@/assets/images/train.png");
  }
  
  .rating-text {
    margin-top: 0px;
    color: lightgray;
    font-size: 0.7rem;
  }
  .rating-start {
    font-size: 1.2rem;
  }
  .multiselect__content {
    overflow-x: hidden;
  }
  
  .duration-badge {
    height: 1.5em;
    line-height: 1.5em;
    width: 1.5em;
  }
  
  .duration-badge-circle {
    font-size: 25px;
    color: #ffffff;
  }
  
  .duration-badge-number {
    font-size: 12px;
    color: #253746;
  }
  
  .duration-badge-circle-green {
    font-size: 25px;
    color: #28a745;
    border: #18632a;
  }
  
  div.amber > span.duration-badge-circle-green  {
    color: orange;
    border: rgb(177, 115, 2);
  }
  
  div.red > span.duration-badge-circle-green {
    color: orangered;
    border: rgb(146, 41, 2)
  }
  
  .duration-badge-number-green {
    font-size: 12px;
    color: white;
  }
  </style>