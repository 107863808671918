<template>
  <div
    class="container booking"
    :id="'appointment-box-' + booking.id"
    :style="appointmentstyle"
    :class="{
      'white-border': booking.appointmenttimeoutsidebooking,
      selectedbooking: booking.id === highlight,
      allday: booking.isAllDay,
    }"
  >
    <div class="second-div">
      <!-- Job type -->
      <h6 v-if="showdate">{{ booking.bookingdate }}</h6>
      <div class="jobtype" v-if="booking.jobtype === 'Personal'">
        <h6>
          {{ firstlineof(booking.summary) }}
        </h6>
      </div>
      <div v-if="booking.subtype === 'Personal'">{{ description }}</div>
      <div class="jobtype font-weight-bold" v-else-if="booking.jobtype">
        <h5 :key="booking.updatedAt">
          {{ title }}
          {{
            booking.jobtype === "property visit"
              ? " - " + actProperty.formatAddress(booking.address, ", ")
              : ""
          }}
        </h5>
      </div>

      <!-- Key collection, date of master job-->
      <div class="dateofmaster" v-if="booking.subtype === 'Key'">
        Date of master job: {{ booking.leadbooking.bookingdate }}
      </div>

      <div v-else>
        <!-- Address -->
        <div
          class="address mb-1"
          v-if="booking.addressPreviewLine1 || booking.addressPreviewLine2"
        >
          {{ booking.address.postcode }}
          <br />{{ booking.addressPreviewLine1 }}
        </div>

        <!-- bed, bath, reception, sq footage, property type, furnished -->
        <div
          v-if="booking.propertyspec"
          class="mb-1"
          style="white-space: normal"
        >
          <span v-if="booking.propertyspec.bedrooms"
            >{{ booking.propertyspec.bedrooms }} Beds
          </span>
          <span v-if="booking.propertyspec.bathrooms"
            >{{ booking.propertyspec.bathrooms }} Baths
          </span>
          <span v-if="booking.propertyspec.receptions"
            >{{ booking.propertyspec.receptions }} Recs
          </span>
          <span v-if="booking.propertyspec.sqft"
            >{{ booking.propertyspec.sqft }} Sq Ft
          </span>
          <span v-if="booking.propertyspec.propertytype"
            >{{ booking.propertyspec.propertytype }}
          </span>
          <span v-if="booking.propertyspec.furnished"
            >{{ shortfurnished }}
          </span>
        </div>

        <!-- Customer -->
        <div
          class="customer"
          v-if="
            booking.customer &&
            (booking.customer.companyName || booking.customer.branchName)
          "
        >
          {{ booking.customer.companyName }} -
          {{ booking.customer.branchName }}
        </div>

        <!-- Summary -->
        <div
          class="summary"
          v-if="booking.subtype != 'Personal' && booking.summary"
        >
          {{ booking.summary }}
        </div>
        <!-- Description -->
        <div
          class="description"
          v-if="booking.subtype != 'Personal' && booking.description"
        >
          {{ convertToPlain(booking.description) }}
        </div>
        <!-- Location -->
        <div class="location" v-if="booking.location">
          {{ booking.location }}
        </div>

        <!-- Time -->
        <div class="timing" v-if="booking.isAllDay">All day</div>
        <div class="timing" v-else-if="booking.startdate || booking.enddate">
          {{ booking.starttime }}
          to
          {{ booking.endtime }}
        </div>
        <div class="timing" v-if="booking.bookings && booking.bookings.length">
          <span
            :style="{
              color: bookedCount() > 9 ? 'red' : '',
              marginLeft: '5px',
            }"
          >
            {{ bookedCount() }}/{{ totalBookingCount() }}
          </span>
        </div>
      </div>

      <DxContextMenu
        v-if="!pischeduler"
        :data-source="rightclickoptions"
        displayExpr="name"
        :width="200"
        :target="'#appointment-box-' + booking.id"
        @item-click="itemClick"
      />
    </div>
    <div class="first-div">
      <i
        class="fas fa-skull-crossbones icon-botton-right"
        style="color: yellow"
        v-if="booking.checkoutWithoutDataentry"
        :title="skulltitle"
      ></i>
      <i
        class="fa fa-exclamation-circle icon-botton-right"
        style="color: yellow"
        v-if="booking.provisional"
        :title="`Provisional booking: ${booking.provisionalnotes}`"
      ></i>
      <i
        class="fa fa-check-circle icon-botton-right"
        style="color: yellowgreen"
        v-if="
          !booking.issued &&
          !booking.provisional &&
          booking.jobtype === 'property visit' &&
          booking.pvconfirmed
        "
        title="Job fully confirmed"
      ></i>
      <i
        class="fa fa-check-circle icon-botton-right"
        style="color: yellowgreen"
        v-else-if="
          !booking.issued &&
          !booking.provisional &&
          booking.tenantattending != 'unsure' &&
          booking.confirmaccess
        "
        title="Job fully confirmed"
      ></i>
      <i
        class="fa fa-star icon-botton-right"
        style="color: yellow"
        v-if="booking.issued"
        :title="issueTitle"
      ></i>
      <i
        class="fas fa-lock icon-botton-right"
        style="color: yellow"
        v-if="booking.locked"
        :title="booking.lockedby"
      ></i>
      <i
        class="fas fa-pound-sign icon-botton-right"
        style="color: yellow"
        v-if="paymentAdvanceFlag"
        title="payment in advance"
      ></i>
      <i
        class="fas fa-sync-alt icon-botton-right"
        style="color: yellow"
        v-if="previousPIDifferent()"
        :title="previousbookinginspector"
      ></i>
    </div>
    <div
      v-if="
        showtraveltime && booking.jobtype !== 'Personal' && !booking.googleid
      "
    >
      <div
        class="third-div"
        v-if="booking.fromDurationSecs >= 0 || booking.toDurationSecs >= 0"
      >
        <div
          v-if="booking.toDurationSecs >= 0"
          style="position: fixed; top: 0; right: 0"
          :title="booking.toDurationText"
        >
          <span class="fa-stack duration-badge">
            <i
              class="fa fa-circle duration-badge-circle"
              :class="{ 'top-badge-circle': booking.topTo }"
            ></i>
            <strong
              class="fa-stack-1x duration-badge-number"
              :class="{ 'top-badge-text': booking.topTo }"
              v-if="booking.toDurationSecs < 5941"
              >{{
                booking.toDurationMins ? booking.toDurationMins : "0"
              }}</strong
            >
            <i
              v-else
              class="fa-stack-1x duration-badge-number fas fa-times"
            ></i>
          </span>
        </div>

        <div
          v-if="booking.fromDurationSecs >= 0"
          style="position: fixed; bottom: 0; left: 0"
          :title="booking.fromDurationText"
        >
          <span class="fa-stack duration-badge">
            <i
              class="fa fa-circle duration-badge-circle"
              :class="{ 'top-badge-circle': booking.topFrom }"
            ></i>
            <strong
              class="fa-stack-1x duration-badge-number"
              :class="{ 'top-badge-text': booking.topFrom }"
              v-if="booking.fromDurationSecs < 5941"
              >{{
                booking.fromDurationMins ? booking.fromDurationMins : "0"
              }}</strong
            >
            <i
              v-else
              class="fa-stack-1x duration-badge-number fas fa-times"
            ></i>
          </span>
        </div>
      </div>

      <div
        class="third-div"
        v-if="booking.traveltime.from > 0 || booking.traveltime.to > 0"
      >
        <div
          v-if="booking.traveltime.from > 0"
          style="position: fixed; top: 0; right: 0"
          :title="booking.traveltime.fromText"
        >
          <span class="fa-stack duration-badge">
            <i class="fa fa-circle duration-badge-circle-green"></i>
            <strong
              class="fa-stack-1x duration-badge-number-green"
              v-if="booking.traveltime.from < 5941"
              >{{
                booking.traveltime.fromMins ? booking.traveltime.fromMins : "0"
              }}</strong
            >
            <i
              v-else
              class="fa-stack-1x duration-badge-number-green fas fa-times"
            ></i>
          </span>
        </div>

        <div
          v-if="booking.traveltime.to > 0"
          style="position: fixed; bottom: 0; left: 0"
          :title="booking.traveltime.toText"
        >
          <span class="fa-stack duration-badge">
            <i class="fa fa-circle duration-badge-circle-green"></i>
            <strong
              class="fa-stack-1x duration-badge-number-green"
              v-if="booking.traveltime.to < 5941"
              >{{
                booking.traveltime.toMins ? booking.traveltime.toMins : "0"
              }}</strong
            >
            <i
              v-else
              class="fa-stack-1x duration-badge-number-green fas fa-times"
            ></i>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {
  computed,
  inject,
  onMounted,
  ref,
  defineProps,
  defineEmits,
  PropType,
  toRaw,
} from "vue";
import { useStore } from "vuex";
import _get from "lodash/get";
import {
  SelectOption,
  Booking,
  Auditlog,
  Auditlogvaluechange,
  User,
  Customer,
  Tenant,
  Address,
  EmailLogs,
  EmailLog,
  Inspector,
} from "@/models";
import { getAppointmentcolour, bookingdateformat } from "@/utilities";
import DxContextMenu from "devextreme-vue/context-menu";
import moment from "moment-timezone";
import { useToast } from "vue-toastification";
import { datetimeToUTC } from "@/utilities";
// Props
const props = defineProps({
  highlight: { type: String as PropType<string>, default: "" },
  picolour: { type: Boolean as PropType<boolean>, default: true },
  rightclick: { type: Boolean as PropType<boolean>, default: true },
  showdate: { type: Boolean as PropType<boolean>, default: false },
  templateModel: { type: Object as PropType<any>, default: {} },
  showtraveltime: { type: Boolean as PropType<boolean>, default: false },
});

// Injected properties
const toasted = useToast();
const actProperty: any = inject("actProperty");
const emit = defineEmits([
  "locateBooking",
  "showSubJobModal",
  "showBookingCancelModal",
]);
const saveBooking = async (booking: Booking): Promise<Booking> => {
  return await store.dispatch('diary/saveCloneTenantResponse', booking);
};
const smsReplyFn = async (payload: { from: string; body: any })=> {
  return await store.dispatch('diary/smsReplyFn', payload);
};
// Vuex store
const store = useStore();

const cognitoUser = computed(() => store.getters["auth/cognitoUser"]);
const dictionary = computed(() => store.getters["dictionary/current"]);
const inspectorList = computed(() => store.getters["diary/inspectorlist"]);
const email = computed(() => store.getters["auth/email"]);
const showbookingswithproblems = computed(
  (): boolean => store.getters["diary/showbookingswithproblems"]
);
const user = computed((): User => store.getters["usermanagement/current"]);
const showportfoliobookings = computed(
  (): boolean => store.getters["diary/showportfoliobookings"]
);
const prepdate = ref("");
const cidate = ref("");
const previousbookinginspector = ref("");
const getBookingsUnauthenticatedForInspectorWithDate = async (payload?: {
  date?: string;
  inspectorid?: string;
}): Promise<Booking[]> => {
  return await store.dispatch(
    "diary/getBookingsCloneUnauthenticatedForInspectorWithDate",
    payload
  );
};

const booking = computed(() => props.templateModel?.appointmentData);
const title = computed(() => {
  let bookingtitle = actProperty.getBookingTitle(
    booking.value,
    dictionary.value
  );
  if (booking.value.jobtype && booking.value.titlenotes) {
    bookingtitle = `${bookingtitle}: ${booking.value.titlenotes}`;
  } else {
    bookingtitle = `${bookingtitle}`;
  }
  return bookingtitle;
});
const paymentAdvanceFlag = computed(() => {
  let paymentAdvanceFlag = false;
  if (booking.value.jobtype === "checkout" || booking.value.jobtype === "soc") {
    paymentAdvanceFlag =
      booking.value.customer.accountsettings.paymentinadvcosoc;
  } else if (
    booking.value.jobtype === "inventory" ||
    booking.value.jobtype === "checkin"
  ) {
    paymentAdvanceFlag =
      booking.value.customer.accountsettings.paymentinadvinvci;
  }
  // https://trello.com/c/rSOlSr3W/720-payment-in-advance-logo
  if (paymentAdvanceFlag) {
    let paidFlagArray = booking.value.invoices.map((f) => f?.fees[0]?.paid);
    const allFlagged = paidFlagArray.every((item) => item);
    if (allFlagged) {
      paymentAdvanceFlag = false;
    }
  }
  return paymentAdvanceFlag;
});
const firstlineof = (textdata: string) => {
  let val = "";
  if (textdata) {
    let plaintext = convertToPlain(textdata);
    let lines = plaintext.split("\n");
    if (lines && lines.length) val = lines[0];
  }
  return val;
};

const shortfurnished = computed(() => {
  let shortfurnished: string = booking.value?.propertyspec?.furnished;
  switch (shortfurnished) {
    case "Unfurnished":
      shortfurnished = "Unf";
      break;
    case "Part furnished":
      shortfurnished = "Part";
      break;
    case "Fully furnished":
      shortfurnished = "Fur";
      break;
    case "Fully furnished with linen":
      shortfurnished = "FurLin";
      break;
  }
  return shortfurnished;
});

const rightclickoptions = computed(() => {
  let options: SelectOption[] = [];
  if (
    !booking.value.locked ||
    (booking.value.locked && booking.value.lockedby === email.value)
  ) {
    if (booking.value && props.rightclick && !booking.value.bookings) {
      if (
        booking.value.googleid ||
        booking.value.jobtype === Booking.PERSONAL ||
        booking.value.subtype === Booking.PERSONAL ||
        booking.value.jobtype === "property visit"
      ) {
        options = [];
        options.push({
          name: "Delete",
          slug: "Delete",
          icon: "fas fa-times-circle",
        });
        if (booking.value.jobtype === "property visit") {
          for (let i = 1; i <= 4; i++) {
            options.push({
              name: `Option ${i}`,
              slug: `option${i}`,
              icon: "fa fa-check-circle", // Change the icon as needed
            });
          }
        }
      } else {
        if (booking.value.subtype) {
          options.push({
            name: `Master with ${booking.value.leadbooking?.inspector?.name}`,
            slug: booking.value.leadbooking?.id,
            date: booking.value.leadbooking?.startdate,
            icon: "far fa-calendar-alt",
          });
        }

        let prepjobpresent = false;
        if (booking.value.subbookings.length) {
          booking.value.subbookings.forEach((s: Booking) => {
            if (s.subtype === "Shared") {
              options.push({
                name: `Shared job with ${s.inspector?.name}`,
                slug: s.id,
                date: s.startdate,
                icon: "far fa-calendar-alt",
              });
            } else if (s.subtype === "Prep") {
              prepjobpresent = true;
              options.push({
                name: `Prep job with ${s.inspector?.name}`,
                slug: s.id,
                date: s.startdate,
                icon: "far fa-calendar-alt",
              });
            } else if (s.subtype === "Key") {
              options.push({
                name: `Key collection ${s.inspector?.name}`,
                slug: s.id,
                date: s.startdate,
                icon: "far fa-calendar-alt",
              });
            } else if (s.subtype === "Revisit") {
              options.push({
                name: `Revisit ${s.inspector?.name}`,
                slug: s.id,
                date: s.startdate,
                icon: "far fa-calendar-alt",
              });
            }
          });
        }

        if (booking.value.subtype != Booking.PREP && !prepjobpresent) {
          options.push({
            name: "Create prep job",
            slug: "Create prep job",
            icon: "fas fa-columns",
          });
        }
        if (booking.value.subtype != Booking.SHARED) {
          options.push({
            name: "Create shared job",
            slug: "Create shared job",
            icon: "fas fa-user-friends",
          });
        }

        if (!booking.value.subtype) {
          options.push({
            name: "Create key collection",
            slug: "Create key collection",
            icon: "fas fa-key",
          });
          options.push({
            name: "Create revisit",
            slug: "Create revisit",
            icon: "fas fa-retweet",
          });
        }

        options.push({
          name: "Delete",
          slug: "Delete",
          icon: "fas fa-times-circle",
        });
      }
    }
  }
  return options;
});

const convertToPlain = (html: string) => {
  var tempDivElement = document.createElement("div");
  tempDivElement.innerHTML = html;
  return tempDivElement.textContent || tempDivElement.innerText || "";
};

const itemClick = async (e: any) => {
  if (
    !booking.value.locked ||
    (booking.value.locked && booking.value.lockedby === email.value)
  ) {
    if (
      e.itemData.name.startsWith("Shared job") ||
      e.itemData.name.startsWith("Prep job") ||
      e.itemData.name.startsWith("Key collection") ||
      e.itemData.name.startsWith("Revisit") ||
      e.itemData.name.startsWith("Master")
    ) {
      emit("locateBooking", {
        bookingid: e.itemData.slug,
        date: e.itemData.date,
      });
    } else if (e.itemData.name === "Create shared job") {
      showSubJobModal(Booking.SHARED);
    } else if (e.itemData.name === "Create prep job") {
      showSubJobModal(Booking.PREP);
    } else if (e.itemData.name === "Create key collection") {
      showSubJobModal(Booking.KEY);
    } else if (e.itemData.name === "Create revisit") {
      showSubJobModal(Booking.REVISIT);
    } else if (e.itemData.name === "Delete") {
      cancelBooking();
    } else if (
      booking.value.jobtype === "property visit" &&
      ["option1", "option2", "option3", "option4"].includes(e.itemData.slug)
    ) {
      await bookingConfimationFn(e.itemData.slug, booking.value);
    }
  }
};

const pvmap: Map<string, string> = new Map([
  ["option1", "yes"],
  ["option2", "yes"],
  ["option3", "no"],
  ["option4", "no"],
]);

const findTenant = (bbooking: Booking, emailAddress: string): Tenant => {
  let tenant: Tenant = undefined;
  let paramemail = emailAddress as string;
  if (bbooking?.tenants?.length) {
    tenant = bbooking.tenants.find((t) => {
      return t.ttemail && paramemail && t.ttemail.trim() === paramemail.trim();
    });
  }
  return tenant;
};

const bookingConfimationFn = async (response: string, newbooking: Booking) => {
  // code here
  // if(newbooking.tenants[0]?.ttmobile) {
  //   await smsReplyFn({
  //     from: newbooking.tenants[0].ttmobile,
  //     body: response,
  //   });
  // } else 
  if (newbooking.id && response) {
    newbooking = new Booking({...newbooking, inspector: new Inspector({id: newbooking.inspector.id})});
    let swapbooking: Booking | undefined = undefined;
    // Check map to interprete the new response from existing
    let map: Map<string, string> = undefined;
    let emailresponse = response as string;
    if (newbooking?.jobtype === "property visit") {
      map = pvmap;
    }
    if (map && map.has(emailresponse)) {
      emailresponse = map.get(emailresponse);
    }
    let emailAddress = newbooking.tenants[0].ttemail;
    if (newbooking && newbooking.isTenantEmail(emailAddress as string)) {
      const tenant = findTenant(newbooking, emailAddress);
      if (
        tenant.attending === "" ||
        tenant.attending === "unsure" ||
        emailresponse === tenant.attending
      ) {
        if (newbooking.jobtype === "property visit") {
          let bookingdate = moment(newbooking.startdate).format(
            "DD-MM-YYYY"
          );
          let insectorBookings =
          await getBookingsUnauthenticatedForInspectorWithDate({
            date: bookingdate,
            inspectorid: newbooking.inspector.id,
          });
          let data = await actProperty.processPvConfimrationResponse(
            newbooking,
            response,
            insectorBookings
          );
          if(data?.message) {
            toasted.error(data.message);
          } else {
            newbooking = data.booking;
            swapbooking = data.swapbooking;
          }
        }
        // Note tenant response inside each tenant object as well
        // This is so that we can tell which tenant has responded yes or no
        if (emailresponse === "yes" || emailresponse === "no") {
          if (newbooking?.tenants) {
            const index = newbooking.tenants.findIndex(
              (t) => t.ttemail.trim() === (emailAddress as string)
            );
            if (index >= 0) {
              newbooking.tenants[index].attending = emailresponse;
            }
          }
        }
        let log = new EmailLog({
          inout: "in",
          tenantresponse: response,
          responsedescription: response,
          responseemailaddress:emailAddress,
          logdate: moment().utc().format(bookingdateformat),
          unread: false,
        });
        newbooking.emaillogs.tenantemaillogs.push(log);
        await saveBooking(newbooking);
        if (swapbooking && swapbooking.id != newbooking.id){
          let swapNewBooking = new Booking({...swapbooking, inspector: new Inspector({id: swapbooking.inspector.id})});
          saveBooking(swapNewBooking);
        }   
      } else {
        toasted.error(
          "Sorry, you have already made a selection for this appointment. Please call our office on 0208 896 2232 to discuss. Our offices are open 9:00 till 5:30 Monday to Friday, 9:00 till 3:30 on Saturday."
        );
      }
    } else {
      toasted.error("Booking not found!");
    }
  }
};

const bookedCount = () => {
  return booking.value?.bookings.filter(
    (booking: Booking) => booking.status === "Booked"
  ).length;
};
const totalBookingCount = () => {
  let total = booking.value?.bookings.length;
  if (total < 9) total = 9;
  return total;
};

const showSubJobModal = (subjobtype: string) => {
  emit("showSubJobModal", booking.value, subjobtype);
};

const cancelBooking = () => {
  emit("showBookingCancelModal", booking.value);
};

const issueTitle = computed(() => {
  let findIssueData = actProperty.findLastIssueData(
    booking.value.auditlogs,
    "issued"
  );
  if (
    booking.value.issued &&
    (findIssueData === null || booking.value.auditlogs.length === 0)
  )
    return "Job issued to client";
  let date = moment(findIssueData.datetime).format("DD-MM-YYYY");
  let username = findIssueData.user;
  let title = `Issued - ${date} - ${username}`;
  return title;
});

const appointmentstyle = computed(() => {
  if (!props.picolour) return "";
  let style = "";
  let backgroundcolor = appointmentcolour.value
  if (
    (!booking.value.bookings || booking.value?.bookings?.length === 0) &&
    booking.value.id === props.highlight
  ) {
    style = `background-image: conic-gradient(from var(--border-angle), ${backgroundcolor}, ${backgroundcolor} 50%, ${backgroundcolor}), conic-gradient(from var(--border-angle), transparent 20%, #253746, #eadb40);`;
  } else {
    style = `background: ${backgroundcolor};`;
  }

  if (
    showbookingswithproblems.value &&
    (!booking.value.bookingrequiringpreviousreport ||
      (booking.value.basereport &&
        booking.value.basereport.id &&
        booking.value.basereport.firstexportedbywithcutoff &&
        booking.value.targetreport &&
        booking.value.targetreport &&
        booking.value.targetreport.id))
  ) {
    style = `${style}; background: ${backgroundcolor}55;`;
  }

  if (showportfoliobookings.value) {
    const customerids = user.value.customers.map((c: Customer) => c.id);
    if (!customerids.includes(booking.value?.customer?.id)) {
      style = `${style}; background: ${backgroundcolor}55;`;
    }
  }

  if (
    booking.value.jobtype === "property visit" &&
    booking.value.pvconfirmed
  ) {
    // Based on background color, we need to derive text color that makes the text clear and legible
    let textcolor = actProperty.getContrastYIQ(backgroundcolor);
    style = `${style}; color: ${textcolor};`;
  }
  return style;
});

const appointmentcolour = computed(() =>
  getAppointmentcolour(booking.value, inspectorList.value)
);

const pischeduler = computed(() => {
  const assignedGroups = _get(
    cognitoUser.value,
    'signInUserSession.accessToken.payload["cognito:groups"]',
    []
  );
  const isDiaryUser = assignedGroups.some((assignedGroup: string) =>
    ["DiaryUser"].includes(assignedGroup)
  );
  return screen.width <= 760 && isDiaryUser;
});

const skulltitle = computed(() => {
  let title = "";
  if (booking.value.checkoutWithoutPreviousReport) {
    title = "Previous report not present";
  } else if (booking.value.checkoutWithoutDataentry) {
    if (!booking.value.dataentryreport) title = "Dataentry report is missing";
    else if (booking.value.dataentryreport.dataentrystatus != "Upload Complete")
      title = "Dataentry report is not completed by dataentry team";
  }
  return title;
});

const description = computed({
  get: () => {
    var val = booking.value.description;
    if (booking.value.pinotes)
      val = val ? `${val}\n${booking.value.pinotes}` : booking.value.pinotes;
    return val;
  },
  set: (val: string) => {
    booking.value.description = val;
  },
});

onMounted(() => {
  if (booking.value.subtype === Booking.PREP) {
    if (booking.value.leadbooking?.id) {
      cidate.value = booking.value.leadbooking.bookingdate;
    }
  } else if (booking.value.subbookings) {
    booking.value.subbookings.forEach((b: Booking) => {
      if (b.subtype === Booking.PREP) {
        prepdate.value = b.bookingdate;
      }
    });
  }
});

const previousPIDifferent = () => {
  let value = false;
  if (
    booking.value?.previousbooking?.id &&
    booking.value.previousbooking.inspector?.id != booking.value.inspector?.id
  ) {
    if (
      booking.value?.previousbooking.jobtype === "checkout" ||
      booking.value?.previousbooking.jobtype === "soc" ||
      booking.value?.previousbooking.subtype === "Prep"
    ) {
      if (
        booking.value?.internaljobtype === "Inv CI - off upload" ||
        booking.value?.internaljobtype === "Check-In - off ACT report" ||
        booking.value?.internaljobtype === "Check-In - off upload"
      ) {
        value = true;
        previousbookinginspector.value =
          booking?.value?.previousbooking?.inspector?.name;
      }
    }
  } else if (
    booking.value?.subbookings &&
    booking.value?.subbookings.length > 0
  ) {
    let prep = booking.value?.subbookings.find((b) => b.subtype === "Prep");
    let keypickup = booking.value?.subbookings.find((b) => b.subtype === "Key");
    let revisit = booking.value?.subbookings.find(
      (b) => b.subtype === "Revisit"
    );
    if (prep?.id && prep.inspector?.id != booking.value.inspector?.id) {
      value = true;
      previousbookinginspector.value = prep.inspector.name;
    } else if (
      keypickup?.id &&
      keypickup.inspector?.id != booking.value.inspector?.id
    ) {
      value = true;
      previousbookinginspector.value = keypickup.inspector.name;
    } else if (
      revisit?.id &&
      revisit.inspector?.id != booking.value.inspector?.id
    ) {
      value = true;
      previousbookinginspector.value = revisit.inspector.name;
    }
  } else if (
    booking.value?.leadbooking?.id &&
    booking.value?.inspector?.id != booking.value?.leadbooking?.inspector?.id
  ) {
    value = true;
    previousbookinginspector.value =
      booking.value?.leadbooking?.inspector?.name;
  }

  return value;
};
</script>

<style scoped lang="scss">
.booking {
  white-space: normal !important;
  overflow: hidden !important;
}

.container {
  position: relative;
  overflow: hidden !important;
  width: 100%;
  height: 100%;
}

.first-div {
  position: absolute;
  background: transparent;
  overflow: hidden !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 3px;
  z-index: 4;
}

.icon-botton-right {
  position: absolute;
  align-self: flex-right;
  bottom: 0;
  right: 0;
  padding: 3px;
}

.icon-botton-right:nth-child(2) {
  right: 25px;
}

.icon-botton-right:nth-child(3) {
  right: 50px;
}

.icon-botton-right:nth-child(4) {
  right: 75px;
}

.icon-botton-right:nth-child(5) {
  right: 100px;
}

.second-div {
  position: absolute;
  background: transparent;
  overflow: hidden !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 3px;
  z-index: 3;
}

.third-div {
  position: absolute;
  background: transparent;
  overflow: hidden !important;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  padding: 0px;
  z-index: 5;
}

.duration-badge {
  height: 1.5em;
  line-height: 1.5em;
  width: 1.5em;
}

.duration-badge-circle {
  font-size: 20px;
  color: #ffffff;
}

.duration-badge-number {
  font-size: 12px;
  color: #253746;
}

.duration-badge-circle-green {
  font-size: 20px;
  color: #28a745;
}

.duration-badge-number-green {
  font-size: 12px;
  color: white;
}

.top-badge-circle {
  color: yellow;
}

.top-badge-text {
  color: #253746;
}

.allday {
  padding: 0 0 0 3px;
}

.white-border {
  border: 3px dashed rgba(250, 250, 250, 0.5);
}

.jobtype {
  white-space: normal !important;
}

.address {
  white-space: normal !important;
}

.customer {
  white-space: normal !important;
}

.description {
  white-space: normal !important;
}

.summary {
  white-space: normal !important;
}

.location {
  white-space: normal !important;
}

.timing {
  white-space: normal !important;
}

.dateofmaster {
  white-space: normal !important;
}

.dx-scheduler-appointment {
  /*background: transparent !important;*/
}
</style>
<style lang="scss">
$light-grey: #ecf0f1;
$grey: darken($light-grey, 15%);
$blue: #007aff;
$white: #fff;
$black: #333;

.dx-submenu {
  background-color: $light-grey;
  border-bottom-width: 0px;
  border-radius: 4px;
  box-shadow: 0 3px 6px 0 rgba($black, 0.2);
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  left: 0;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  z-index: 1000000;

  li.dx-menu-item-wrapper {
    border-bottom: 2px solid $grey;
  }

  li {
    &:first-of-type {
      margin-top: 4px;
    }

    &:last-of-type {
      margin-bottom: 4px;
      border-bottom: 0px;
    }
  }
}

.dx-scheduler-appointment {
  overflow-x: hidden;
  overflow-y: hidden;
}

.selectedbooking {
  padding: 10px !important;
  height: inherit;
  --border-size: 7px;
  --border-angle: 0turn;
  background-size: calc(100% - (var(--border-size) * 2))
      calc(100% - (var(--border-size) * 2)),
    cover;
  background-position: center center;
  background-repeat: no-repeat;

  animation: bg-spin 3s linear infinite;

  @keyframes bg-spin {
    to {
      --border-angle: 1turn;
    }
  }

  &:hover {
    animation-play-state: paused;
  }
}

.hide {
  display: none;
}

@property --border-angle {
  syntax: "<angle>";
  inherits: true;
  initial-value: 0turn;
}
</style>
